import { Box } from '@mui/material';
import { styled } from '@mui/system';

// pass any props to the Box component because BoxProps still show a warning
export const StyledFormFrame = styled(Box)<any>(({ theme }) => ({
    'padding': theme.spacing(4),
    'min-height': '540px',
    'width': '520px',
    'borderRadius': '32px',
    'border': '1px solid rgba(89, 240, 129, 0.2)',
    'backdropFilter': 'blur(10px)',
    'backgroundColor': 'rgba(6, 6, 21, 0.2)',
    'boxShadow': `
        inset 1px 1px 1px -0.5px rgb(58, 58, 210),
        inset -1px 1px 1px -0.5px rgb(88, 240, 129),
        inset 0 32px 64px 0 rgba(58, 58, 210, 0.08),
        inset 0 32px 64px 0 rgba(88, 240, 129, 0.08)
    `,
    'opacity': 1,
    // Mobile styles
    [theme.breakpoints.down('sm')]: {
        padding: '32px',
        height: '100%',
        width: '100%',
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        borderRadius: '32px',
        padding: theme.spacing(4),
        boxShadow:
            '0px 0px 1px 0.5px rgba(255, 255, 255, 0.15) inset, 0px 16px 64px -24px rgba(255, 255, 255, 0.15) inset',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            boxShadow: 'none',
        },
    },
}));
