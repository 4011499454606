export const getDiscordUrl = (type: string, state?: string) => {
    const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;
    const redirectUri = getRedirectUriForDiscord();
    const scope = process.env.REACT_APP_DISCORD_SCOPE;
    const stateParam = state ? `&state=${state}` : '';

    if (type === 'app') {
        return `discord://-/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}${stateParam}`;
    }

    return `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}${stateParam}`;
};

export const GoogleScopes =
    'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile' as const;

export const getRedirectUriForGoogle = () => window.location.origin + '/google-callback';
export const getRedirectUriForDiscord = () => window.location.origin + '/discord-callback';
