import { FC, ReactNode } from 'react';
import { Stack, useTheme } from '@mui/material';
import { DotOutline } from '@phosphor-icons/react';
import Typography from '@mui/material/Typography';

interface IInfoTextProps {
    text: ReactNode;
}

export const InfoText: FC<IInfoTextProps> = ({ text }) => {
    const theme = useTheme();

    return (
        <Stack direction="row" alignItems="center">
            <DotOutline
                style={{ flexShrink: 0 }}
                weight="fill"
                size={24}
                color={theme.palette.primary.main}
            />
            <Typography fontWeight={600}>{text}</Typography>
        </Stack>
    );
};
