import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { BrandTextField } from '../../brand-components/brand-text-field';
import { PasswordField } from '../../brand-components/password-field';
import { useDependencies } from 'ioc';
import { SignupStore } from '../../../stores/signup.store';
import Divider from '@mui/material/Divider';
import { ButtonGroup, IconButton } from '@mui/material';
import { LoadStatus } from '../../../../common/enums/load-status';
import { SocialDiscord } from '../../assets/social-discord';
import { SocialGoogle } from '../../assets/social-google';
import { SocialApple } from '../../assets/social-apple';
import { SocialSignupStore } from '../../../stores/social-signup.store';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { getDiscordUrl, GoogleScopes } from '../../../utils/third-party-utils';

export const UserEmailPasswordForm = observer(() => {
    const [
        { state, credentialsFormState },
        {
            loginDiscordLoadStatus,
            loginGoogleLoadStatus,
            loginAppleLoadStatus,
            handleGoogleSignup,
            handleAppleSignup,
        },
    ] = useDependencies(SignupStore, SocialSignupStore);
    const { email, password } = credentialsFormState.$;

    const onGoogleLoginSuccess = async (
        tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    ) => {
        return handleGoogleSignup(tokenResponse);
    };

    const googleSignup = useGoogleLogin({
        scope: GoogleScopes,
        onSuccess: onGoogleLoginSuccess,
        onNonOAuthError: () => () => {},
        onError: () => {
            window.toastr.error('Google login failed');
        },
    });

    const handleGoogleSignupRequest = () => {
        googleSignup();
    };

    const handleDiscordSignupRequest = () => {
        const appUrl = getDiscordUrl('app', state);
        const webUrl = getDiscordUrl('web', state);

        window.location.href = appUrl;

        setTimeout(() => {
            window.location.href = webUrl;
        }, 2000);
    };

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <BrandTextField
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    variant="standard"
                    placeholder="Email address or username"
                    autoComplete="username"
                    value={email.value}
                    error={email.hasError}
                    onChange={event => {
                        email.onChange(event.target.value);
                    }}
                    onBlur={email.enableAutoValidationAndValidate}
                    helperText={email.error}
                />
                <PasswordField
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    variant="standard"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={password.value}
                    error={password.hasError}
                    onChange={event => {
                        password.onChange(event.target.value);
                    }}
                    onBlur={password.enableAutoValidationAndValidate}
                    helperText={password.error}
                />
            </Box>
            <Divider sx={{ color: '#ffffff40' }}>OR SIGN UP WITH</Divider>
            <ButtonGroup fullWidth sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton
                    sx={{
                        opacity: loginDiscordLoadStatus === LoadStatus.Loading ? 0.3 : 1,
                    }}
                    loading={loginDiscordLoadStatus === LoadStatus.Loading}
                    onClick={handleDiscordSignupRequest}
                >
                    <SocialDiscord />
                </IconButton>
                <IconButton
                    sx={{
                        opacity: loginGoogleLoadStatus === LoadStatus.Loading ? 0.3 : 1,
                    }}
                    loading={loginDiscordLoadStatus === LoadStatus.Loading}
                    onClick={handleGoogleSignupRequest}
                >
                    <SocialGoogle />
                </IconButton>
                <IconButton
                    sx={{
                        opacity: loginAppleLoadStatus === LoadStatus.Loading ? 0.3 : 1,
                    }}
                    loading={loginAppleLoadStatus === LoadStatus.Loading}
                    onClick={handleAppleSignup}
                >
                    <SocialApple />
                </IconButton>
            </ButtonGroup>
        </Box>
    );
});
