import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { RankedPlayStore } from '../../stores/ranked-play-store';
import { Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import Card from '@mui/material/Card';
import { Rank } from '../rank';
import { customNeutral } from '../../../redesigned-modules/root/components/theme-provider/colors';
import { InfoText } from './info-text';

export const AllRanks: FC = observer(() => {
    const [{ allRanks }] = useDependencies(RankedPlayStore);
    const { isDesktop, isMobile } = useScreenSize();

    const theme = useTheme();

    const reversedRanks = allRanks.slice().reverse();

    let content = (
        <Card sx={{ py: 4, px: 2 }}>
            <Stack
                mb={isMobile ? 2 : 3}
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                spacing={isMobile ? 3 : 2}
            >
                {reversedRanks.map(rank => (
                    <Stack
                        flexShrink={0}
                        key={rank.id}
                        direction={isMobile ? 'row' : 'column'}
                        alignItems="center"
                        spacing={3}
                    >
                        <Typography
                            flexBasis={isMobile ? '25%' : undefined}
                            color={customNeutral[200]}
                            fontWeight={600}
                        >
                            {rank.name}
                        </Typography>
                        <Stack
                            flexBasis={isMobile ? '75%' : undefined}
                            alignItems="center"
                            spacing={3}
                            justifyContent={isMobile ? 'space-evenly' : undefined}
                            direction={isMobile ? 'row' : 'column'}
                        >
                            {rank.subRanks.map(subRank => (
                                <Rank key={subRank.id} useNumericName rank={subRank} />
                            ))}
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            <InfoText
                text={
                    <Fragment>
                        Prove your skills by competing against other players and earn your way to
                        the <span style={{ color: theme.palette.secondary.main }}>top ranks</span>{' '}
                        of Balance Bet.
                    </Fragment>
                }
            />
            <InfoText
                text={
                    <Fragment>
                        Ranked games are perfect if you seek a{' '}
                        <span style={{ color: theme.palette.secondary.main }}>
                            strategic, competitive
                        </span>{' '}
                        betting experience.
                    </Fragment>
                }
            />
        </Card>
    );

    if (isDesktop) {
        content = (
            <Stack spacing={2}>
                <Typography fontSize={32} fontWeight={600}>
                    Ranked Games
                </Typography>
                {content}
            </Stack>
        );
    }

    return content;
});
