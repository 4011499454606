import { Box, Stack, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { ExternalSocialLinks } from '../../../utils/external-links';
import { InstagramLogo, XLogo } from '@phosphor-icons/react';
import { BrandLinkButton } from '../../brand-components/brand-button';

export const AuthFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 2,
                px: 3,
                mt: 'auto',
                background: '#07073E',
                color: '#6A6A73',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: '1px solid #58F081',
            }}
        >
            <Typography variant="body2">
                © {new Date().getFullYear()} Balance Bet - All Rights Reserved.
            </Typography>
            <Box>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <BrandLinkButton
                        component={Link}
                        target="_blank"
                        href={ExternalSocialLinks.instagram}
                    >
                        <InstagramLogo color="#3A3AD2" size={24} />
                    </BrandLinkButton>
                    <BrandLinkButton
                        component={Link}
                        target="_blank"
                        href={ExternalSocialLinks.twitter}
                    >
                        <XLogo color="#3A3AD2" size={24} />
                    </BrandLinkButton>
                </Stack>
            </Box>
        </Box>
    );
};
