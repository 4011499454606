import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import {
    GameInvitationCreateModel,
    GameModel,
    GameStatusParam,
    PlayerUsernameModel,
} from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';

@injectable()
export class MyGamesListStore {
    gamesLoadStatus = LoadStatus.None;
    areAllGamesFetched = false;
    games: GameModel[] = [];
    gameToShare?: GameModel;
    gameToShowSportSelection?: GameModel;

    constructor() {
        makeAutoObservable(this);
    }

    fetchGames = async () => {
        if (this.gamesLoadStatus === LoadStatus.Loading || this.areAllGamesFetched) {
            return;
        }

        try {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Loading;
            });

            const { items: games, totalAmount } = await apiClient.gamesGET(
                undefined,
                undefined,
                undefined,
                GameStatusParam.Registered,
                undefined,
                undefined,
                this.games.length,
                ASYNC_LOAD_LIMIT,
            );

            if (games) {
                runInAction(() => {
                    this.games.push(...games);
                });
            }

            if (totalAmount <= this.games.length) {
                this.areAllGamesFetched = true;
            }

            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Error;
            });
        }
    };

    showSportSelection = (game: typeof this.gameToShowSportSelection) => {
        this.gameToShowSportSelection = game;
    };

    hideSportSelection = () => {
        this.gameToShowSportSelection = undefined;
    };

    leaveGame = async (gameId: (typeof this.games)[number]['id']) => {
        try {
            await apiClient.gameParticipationsDELETE(gameId);

            runInAction(() => {
                this.games = this.games.filter(({ id }) => id !== gameId);
            });
        } catch {
            // skip
        }
    };

    startInvitation = (game: typeof this.gameToShare) => {
        this.gameToShare = game;
    };

    endInvitation = () => {
        this.gameToShare = undefined;
    };

    sendInvite = async (usernames: PlayerUsernameModel[]) => {
        if (!this.gameToShare) {
            return;
        }

        try {
            const payload = {
                playerIds: usernames.map(({ id }) => id),
                tournamentId: this.gameToShare.id,
                message: undefined,
            };

            await apiClient.gameInvitationsPOST(new GameInvitationCreateModel(payload));
        } catch {
            //
        }
    };

    getShareLink = async () => {
        const game = this.gameToShare;

        if (!game) {
            throw new Error('Could not share!');
        }

        const link = new URL(`${window.location.origin}/lobby`);
        link.searchParams.set('invitationId', `${game.id}`);

        if (game.isPrivate) {
            const { tokenString } = await apiClient.tokenGET2(game.id);

            link.searchParams.set('token', encodeURIComponent(tokenString ?? ''));
        }

        return link.toString();
    };
}
