import { IdNameModel, MatchModel, OddGroupModel } from '../../api/api';
import { LoadStatus } from '../../enums/load-status';
import { FC } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { MatchSectionProps, MatchSections } from './match-sections';
import { SubsectionGroup } from './subsection-group';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';
import { BetSlipMatchInfo } from '../../../bet-slip/stores/bet-slip-store';
import { useTeamNameFormatter } from '../../hooks/use-team-name-formatter';

export interface MatchOddsProps {
    match: MatchModel;
    oddsLoadStatus: LoadStatus;
    matchSectionId?: IdNameModel['id'];
    matchSections: IdNameModel[];
    oddSubsectionGroups: OddGroupModel[];
    onSelectSection: MatchSectionProps['onChange'];
}

export const MatchOdds: FC<MatchOddsProps> = ({
    match,
    oddSubsectionGroups,
    matchSections,
    oddsLoadStatus,
    matchSectionId,
    onSelectSection,
}) => {
    const formatTeamName = useTeamNameFormatter();

    const betSlipMatchInfo: BetSlipMatchInfo = {
        id: match.id,
        teams: match.teams,
    };

    return (
        <BetSlipMatchContext.Provider value={betSlipMatchInfo}>
            <Box mb={3}>
                <Typography variant="h4" fontWeight="900">
                    {`${formatTeamName(match.teams![0])} - ${formatTeamName(match.teams![1])}`}
                </Typography>
            </Box>
            <Box mb={4}>
                <MatchSections
                    sections={matchSections}
                    onChange={onSelectSection}
                    selectedSectionId={matchSectionId}
                />
            </Box>
            <Box>
                {oddsLoadStatus === LoadStatus.Loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} my={{ xs: 2, sm: 10 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    oddSubsectionGroups.map((subsectionGroup, index) => (
                        <SubsectionGroup key={index} subsectionGroup={subsectionGroup} />
                    ))
                )}
            </Box>
        </BetSlipMatchContext.Provider>
    );
};
