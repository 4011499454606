import { FC } from 'react';
import { PlayerGlobalRankingModel } from '../../../common/api/api';
import { Avatar, LinearProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ColoredBorder } from '../../../common/components/colored-border/colored-border';

interface IGlobalLeaderboardPlayerProps {
    player: PlayerGlobalRankingModel;
    rank: number;
    isCurrentPlayer?: boolean;
}

export const GlobalLeaderboardPlayer: FC<IGlobalLeaderboardPlayerProps> = ({
    player,
    rank,
    isCurrentPlayer,
}) => {
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ height: 48 }}
        >
            <Box flex="0 0 20%">
                <Typography fontSize={20} fontWeight={500} sx={{ wordBreak: 'break-all' }}>
                    #{rank}
                </Typography>
            </Box>
            <Box flex="0 0 35%">
                <Stack direction="row" alignItems="center" spacing={1}>
                    <ColoredBorder toTop borderSize="2px" borderRadius={100}>
                        <Avatar sx={{ width: 44, height: 44 }} src={player.avatarUrl} />
                    </ColoredBorder>
                    <Typography fontWeight={500} sx={{ wordBreak: 'break-all' }}>
                        {isCurrentPlayer ? 'YOU' : player.username}
                    </Typography>
                </Stack>
            </Box>
            <Box flex="0 0 10%">
                <Box
                    sx={{
                        width: 24,
                        height: 24,
                    }}
                >
                    <img src={player.rankInfo?.logoUrl} style={{ width: '100%', height: '100%' }} />
                </Box>
            </Box>
            <Box flex="0 0 35%">
                {player.rankInfo && (
                    <LinearProgress
                        color="secondary"
                        sx={{ width: '100%' }}
                        variant="determinate"
                        value={
                            player.rankInfo.currentScore /
                            (player.rankInfo.maxScore ?? player.rankInfo.currentScore)
                        }
                    />
                )}
            </Box>
        </Stack>
    );
};
