import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { StyledFormFrame } from '../brand-components/brand-form-frame';
import { SignupStore } from '../../stores/signup.store';
import { SignupFormAdvanced } from './signup-form-advanced';
import { SocialSignupStore } from '../../stores/social-signup.store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AvatarStore } from '../../stores/avatar.store';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getRedirectUriForDiscord } from '../../utils/third-party-utils';

export const Signup = provide([SignupStore, SocialSignupStore, AvatarStore])(
    observer(() => {
        const [{ initFromState }] = useDependencies(SignupStore);
        const [searchParams] = useSearchParams();

        useEffect(() => {
            const stateParams = searchParams.get('state');
            const code = searchParams.get('code');

            if (stateParams) {
                initFromState(stateParams, code, getRedirectUriForDiscord());
            }
        }, [initFromState, searchParams]);

        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                <StyledFormFrame sx={{ overflow: 'auto' }}>
                    <SignupFormAdvanced />
                </StyledFormFrame>
            </GoogleOAuthProvider>
        );
    }),
);
