import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { ButtonGroup, IconButton } from '@mui/material';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';

import { LoginStore } from '../../stores/login.store';
import { SocialDiscord } from '../assets/social-discord';
import { SocialGoogle } from '../assets/social-google';
import { BrandButton } from '../brand-components/brand-button';
import { BrandTextField } from '../brand-components/brand-text-field';
import { LoadStatus } from '../../../common/enums/load-status';
import { UnauthenticatedRoutes } from '../../../redesigned-modules/root/components/app/app-unauthenticated';
import { BrandTypography } from '../brand-components/brand-typography';
import { PasswordField } from '../brand-components/password-field';
import { getDiscordUrl, GoogleScopes } from '../../utils/third-party-utils';

export const LoginForm = provide([LoginStore])(
    observer(() => {
        const [
            {
                formState,
                loginLoadStatus,
                loginWithGoogle,
                login,
                onLoadingState,
                offLoadingState,
                isLoginDisabled,
                loginGoogleLoadStatus,
                loginDiscordLoadStatus,
            },
        ] = useDependencies(LoginStore);
        const [searchParams] = useSearchParams();
        const { username, password } = formState.$;
        const navigate = useNavigate();

        const onGoogleLoginSuccess = async (
            tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
        ) => {
            return loginWithGoogle(tokenResponse);
        };

        const googleLogin = useGoogleLogin({
            scope: GoogleScopes,
            onSuccess: onGoogleLoginSuccess,
            onNonOAuthError: () => offLoadingState(),
            onError: () => {
                window.toastr.error('Google login failed');
            },
        });

        const handleSubmit = async (event: any) => {
            try {
                event.preventDefault();

                await login();
                const returnURL = searchParams.get('returnURL');

                if (returnURL) {
                    navigate(decodeURIComponent(returnURL));
                }
            } catch {
                // handle error
            }
        };

        const handleDiscordLogin = () => {
            onLoadingState();

            const state = encodeURIComponent(JSON.stringify({ t: 'login' }));

            const appUrl = getDiscordUrl('app', state);
            const webUrl = getDiscordUrl('web', state);

            window.location.href = appUrl;

            setTimeout(() => {
                window.location.href = webUrl;
            }, 2000);
        };

        const handleGoogleLogin = () => {
            onLoadingState();
            googleLogin();
        };

        return (
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    flex: 1,
                }}
                component="form"
                onSubmit={handleSubmit}
                display="flex"
                flexDirection="column"
                width="100%"
            >
                <Box sx={{ mb: { xs: 2 } }}>
                    <BrandTypography variant="h4" textAlign="center" mb={2}>
                        Welcome back!
                    </BrandTypography>
                    <Typography
                        textAlign="center"
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Don’t have an account?{' '}
                        <Link
                            sx={{ color: theme => theme.palette.primary[250] }}
                            underline="hover"
                            fontWeight="600"
                            component={RouterLink}
                            to="/signup"
                        >
                            Sign Up
                        </Link>{' '}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <BrandTextField
                        sx={{ mb: 3 }}
                        required
                        fullWidth
                        variant="standard"
                        placeholder="Email address or username"
                        autoComplete="username"
                        value={username.value}
                        error={username.hasError}
                        onChange={event => {
                            username.onChange(event.target.value);
                        }}
                        onBlur={username.enableAutoValidationAndValidate}
                        helperText={username.error}
                    />
                    <PasswordField
                        sx={{ mb: 3 }}
                        required
                        fullWidth
                        variant="standard"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password.value}
                        error={password.hasError}
                        onChange={event => {
                            password.onChange(event.target.value);
                        }}
                        onBlur={password.enableAutoValidationAndValidate}
                        helperText={password.error}
                    />
                    <Link
                        mb={3}
                        sx={{ color: theme => theme.palette.primary[250] }}
                        underline="hover"
                        component={RouterLink}
                        to={`/${UnauthenticatedRoutes.forgotPassword}`}
                    >
                        Reset password
                    </Link>
                    <Box width="100%">
                        <Divider sx={{ color: '#ffffff40' }}>OR</Divider>
                        <ButtonGroup fullWidth sx={{ display: 'flex', justifyContent: 'center' }}>
                            <IconButton
                                sx={{
                                    opacity:
                                        loginDiscordLoadStatus === LoadStatus.Loading ? 0.3 : 1,
                                }}
                                loading={loginDiscordLoadStatus === LoadStatus.Loading}
                                onClick={handleDiscordLogin}
                            >
                                <SocialDiscord />
                            </IconButton>
                            <IconButton
                                sx={{
                                    opacity: loginGoogleLoadStatus === LoadStatus.Loading ? 0.3 : 1,
                                }}
                                loading={loginGoogleLoadStatus === LoadStatus.Loading}
                                onClick={handleGoogleLogin}
                            >
                                <SocialGoogle />
                            </IconButton>
                        </ButtonGroup>
                    </Box>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    <BrandButton
                        type="submit"
                        disabled={
                            formState.hasError ||
                            loginLoadStatus === LoadStatus.Loading ||
                            isLoginDisabled
                        }
                        endIcon={<ArrowCircleRight size={32} weight="fill" color="#000" />}
                    >
                        Login
                    </BrandButton>
                </Box>
            </Box>
        );
    }),
);
