import { injectable, inject } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from 'shared';
import { apiClient } from '../../common/api/api-client';
import { PlayerGlobalRankingModel } from '../../common/api/api';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';
import { AuthStore } from '../../common/stores/auth-store';

@injectable()
export class GlobalLeaderboardStore {
    loadStatus = LoadStatus.None;
    areAllPlayersFetched = false;
    globalLeaderboard: PlayerGlobalRankingModel[] = [];
    currentPlayerRank?: number;

    @inject(AuthStore) private readonly authStore!: AuthStore;

    constructor() {
        makeAutoObservable(this);
    }

    fetchLeaderboard = async () => {
        if (this.loadStatus === LoadStatus.Loading || this.areAllPlayersFetched) {
            return;
        }

        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const {
                items: globalLeaderboard,
                totalAmount,
                playerPosition,
            } = await apiClient.getGlobalLeaderboard(
                this.globalLeaderboard.length,
                ASYNC_LOAD_LIMIT,
            );

            runInAction(() => {
                this.globalLeaderboard.push(...globalLeaderboard);
            });

            if (totalAmount <= this.globalLeaderboard.length) {
                this.areAllPlayersFetched = true;
            }

            runInAction(() => {
                this.currentPlayerRank = playerPosition;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    get globalLeaderboardWithoutCurrentUser() {
        return this.globalLeaderboard.filter(({ id }) =>
            this.authStore.me ? id !== this.authStore.me?.id : true,
        );
    }

    get currentPlayer() {
        return this.authStore.me ? new PlayerGlobalRankingModel(this.authStore.me) : undefined;
    }
}
