import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import { LastElementSetter } from '../../../../common/utils/types';
import { DoorOpen, HandWaving, SquareHalf } from '@phosphor-icons/react';
import { RankedGameWithParticipantModel } from '../../../../common/api/api';
import { RankedGamesStore } from '../../../stores/ranked-games-store';
import { RankedGameListItemMobile } from './ranked-game-list-item-mobile';
import { ActionButton } from '../../../../common/components/action-button/action-button';
import {
    customNegative,
    customNeutral,
    customPrimary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { Stack } from '@mui/material';

interface IRankedGamesMobileProps {
    setLastElement: LastElementSetter;
    onLeave: (game: RankedGameWithParticipantModel) => void;
    onRegister: (leagueId: RankedGameWithParticipantModel) => void;
    onShowSportSelection: (leagueId: RankedGameWithParticipantModel) => void;
    onEnter: (leagueId: RankedGameWithParticipantModel) => void;
}

export const RankedGamesMobile: FC<IRankedGamesMobileProps> = observer(
    ({ setLastElement, onLeave, onShowSportSelection, onRegister, onEnter }) => {
        const [{ games }] = useDependencies(RankedGamesStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => (
                    <RankedGameListItemMobile
                        key={game.id}
                        ref={index === games.length - 1 ? setLastElement : undefined}
                        game={game}
                        actions={
                            <Stack direction="row" spacing={1}>
                                <ActionButton
                                    onClick={() => onShowSportSelection(game)}
                                    endIcon={<SquareHalf size={24} />}
                                    variant="contained"
                                    sx={{
                                        background: customPrimary[350],
                                        color: customNeutral[950],
                                        flex: '1 0 0',
                                    }}
                                >
                                    Matches
                                </ActionButton>
                                {game.participant ? (
                                    <Fragment>
                                        <ActionButton
                                            onClick={() => onEnter(game)}
                                            endIcon={<DoorOpen weight="fill" />}
                                            color="primary"
                                            variant="contained"
                                            sx={{
                                                flex: '1 0 0',
                                            }}
                                        >
                                            Enter
                                        </ActionButton>
                                        {game.participant.canLeave && (
                                            <ActionButton
                                                onClick={() => onLeave(game)}
                                                endIcon={<DoorOpen weight="fill" />}
                                                variant="contained"
                                                sx={{
                                                    background: customNegative[600],
                                                    flex: '1 0 0',
                                                }}
                                            >
                                                Leave
                                            </ActionButton>
                                        )}
                                    </Fragment>
                                ) : (
                                    <ActionButton
                                        onClick={() => onRegister(game)}
                                        endIcon={<HandWaving />}
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            flex: '1 0 0',
                                        }}
                                    >
                                        Register
                                    </ActionButton>
                                )}
                            </Stack>
                        }
                    />
                ))}
            </Box>
        );
    },
);
