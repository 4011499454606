import {
    Box,
    Dialog,
    DialogTitle,
    ImageList,
    IconButton,
    Stack,
    DialogActions,
    Slide,
} from '@mui/material';
import { observer } from 'mobx-react';
import { forwardRef, ReactElement, Ref } from 'react';
import { useDependencies } from 'ioc';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { LoadStatus } from 'shared';
import CircularProgress from '@mui/material/CircularProgress';

import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import { AvatarStore } from '../../../stores/avatar.store';
import { AvatarModel } from '../../../../common/api/api';
import { ColoredBorder } from '../../../../common/components/colored-border/colored-border';
import { SignupStore } from '../../../stores/signup.store';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<unknown>;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const AvatarDialog = observer(() => {
    const [
        { usernameAvatarFormState },
        {
            open,
            avatars,
            loadAvatarsStatus,
            avatarCategories,
            selectedCategoryId,
            handleChangeCategory,
            handleCloseAvatarModal,
        },
    ] = useDependencies(SignupStore, AvatarStore);
    const { isMobile } = useScreenSize();
    const selectedAvatar = usernameAvatarFormState.$.avatarFile.value;

    const handleSelectAvatar = (avatar: AvatarModel) => {
        usernameAvatarFormState.$.avatarFile.onChange(avatar);
        handleCloseAvatarModal();
    };

    const handleCloseModal = () => {
        handleCloseAvatarModal();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseModal}
            fullScreen={isMobile}
            maxWidth="md"
            TransitionComponent={Transition}
            slotProps={{
                paper: {
                    sx: {
                        background: 'linear-gradient(to bottom, #0c1a32, #111e42)',
                        borderRadius: 3,
                        padding: 2,
                        color: 'white',
                    },
                },
            }}
        >
            <Stack>
                <IconButton
                    sx={{ color: 'white', alignSelf: 'flex-end' }}
                    onClick={handleCloseModal}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle
                    sx={{
                        fontFamily: 'ClashDisplay',
                        fontWeight: 'bold',
                        fontSize: 28,
                        color: 'white',
                        pt: 0,
                        alignSelf: 'center',
                    }}
                >
                    Select Avatar
                </DialogTitle>
            </Stack>
            <Box flexGrow={1} minWidth={548} minHeight={248}>
                {loadAvatarsStatus === LoadStatus.Loading ? (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <ImageList sx={{ mb: 4 }} cols={5} rowHeight={90} gap={32}>
                        {avatars.map(avatar => (
                            <ColoredBorder
                                key={avatar.id}
                                sx={{
                                    cursor: 'pointer',
                                    width: 84,
                                    height: 84,
                                }}
                                active={selectedAvatar?.id === avatar.id}
                                borderRadius="50%"
                                onClick={() => handleSelectAvatar(avatar)}
                            >
                                <Avatar
                                    sx={{ width: '100%', height: '100%', backgroundColor: 'black' }}
                                    src={avatar.imageUrl}
                                />
                            </ColoredBorder>
                        ))}
                    </ImageList>
                )}
            </Box>
            <DialogActions
                sx={{
                    p: '32px',
                    background: '#100E22',
                    m: '-16px',
                }}
            >
                <Stack width="100%" gap={2} direction="row">
                    {avatarCategories.map(({ imageUrl, id }) => (
                        <Box
                            key={id}
                            sx={{
                                'position': 'relative',
                                '&::before': {
                                    display: selectedCategoryId === id ? 'block' : 'none',
                                    content: '""',
                                    position: 'absolute',
                                    top: '-30px',
                                    left: '-12px',
                                    width: '85px',
                                    height: '3px',
                                    boxShadow: '0px 1px 6px 0px #58F081, 0px 3px 10px 0px #58F081',
                                    background: '#58F081',
                                    transition: 'background-color 0.3s ease',
                                },
                            }}
                            onClick={() => handleChangeCategory(id)}
                        >
                            <Avatar
                                sx={{ width: 60, height: 60, cursor: 'pointer' }}
                                src={imageUrl}
                            />
                        </Box>
                    ))}
                </Stack>
            </DialogActions>
        </Dialog>
    );
});
