import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { formValidatorsWithCustomMessages } from '../../common/utils/form-state-validators';
import { LoadStatus } from '../../common/enums/load-status';
import { apiClient } from '../../common/api/api-client';

import { injectable } from 'ioc';
import { PasswordResetRequestModel } from '../../common/api/api';

@injectable()
export class ForgotPasswordStore {
    isEmailSent = false;
    emailLoadStatus = LoadStatus.None;

    get isLoading() {
        return this.emailLoadStatus === LoadStatus.Loading;
    }

    formState = new FormState({
        email: new FieldState('').validators(
            formValidatorsWithCustomMessages.email(
                'Please enter a valid email address (e.g., name@example.com)',
            ),
        ),
    });

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    sendRecoverEmail = async () => {
        try {
            runInAction(() => {
                this.emailLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            await apiClient.tokenPOST({
                email: this.formState.$.email.value,
            } as PasswordResetRequestModel);

            runInAction(() => {
                this.emailLoadStatus = LoadStatus.Ok;
                this.isEmailSent = true;
            });
        } catch {
            runInAction(() => {
                this.emailLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to validate email');
        }
    };
}
