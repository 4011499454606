export const SocialApple = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="50" height="50" rx="25" fill="#182831" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.0116 21.7106C31.9806 20.4259 30.5341 19.6826 29.1686 19.6826C27.3621 19.6826 26.5988 20.5409 25.3456 20.5409C24.0538 20.5409 23.0716 19.6839 21.5091 19.6839C19.9743 19.6839 18.3426 20.6161 17.3081 22.2094C15.8523 24.4499 16.0988 28.6654 18.4593 32.2576C19.3036 33.5421 20.4311 34.9866 21.9053 35.0004C23.2156 35.0124 23.5863 34.1646 25.3641 34.1546C27.1421 34.1449 27.4783 35.0096 28.7883 34.9974C30.2626 34.9834 31.4521 33.3839 32.2958 32.0991C32.9006 31.1789 33.1258 30.7139 33.5956 29.6736C30.1828 28.3841 29.6341 23.5619 33.0116 21.7106Z"
                fill="#F5F8FF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.7988 18.247C28.455 17.405 28.9535 16.2155 28.7725 15C27.7005 15.074 26.4465 15.7558 25.7145 16.645C25.05 17.452 24.5013 18.6487 24.7148 19.812C25.885 19.8482 27.096 19.149 27.7988 18.247Z"
                fill="#F5F8FF"
            />
        </svg>
    );
};
