import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { BalanceBetLogo } from '../../../../redesigned-modules/root/components/logo/balance-bet-logo';
import { FramerIllustration } from '../../brand-components/tablet-background';
import { ExternalDocsLinks, ExternalSocialLinks } from '../../../utils/external-links';
import { BrandLinkButton } from '../../brand-components/brand-button';
import { InstagramLogo, XLogo } from '@phosphor-icons/react';

export const AuthMobileLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                'position': 'relative',
                'width': '100%',
                'height': '100vh',
                'display': 'flex',
                'overflow': 'hidden',
                'flexDirection': 'column',
                'gap': 2,
                'background': 'linear-gradient(135deg, rgba(88, 240, 129, 0.1) 40%, #0E1826 60%)',
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '-150px',
                    left: '-200px',
                    width: '500px',
                    height: '455px',
                    background:
                        'radial-gradient(circle, rgba(88, 240, 129, 1) 10%, rgba(88, 240, 129, 0.5) 40%, rgba(88, 240, 129, 0) 80%)',
                    filter: 'blur(120px)',
                    opacity: 0.8,
                },
                '&::after': {
                    content: '""',
                    width: '100%',
                    height: '592px',
                    position: 'absolute',
                    transform: 'translate(65%, 100%)',
                    background:
                        'radial-gradient(circle, rgba(58, 58, 210, 1) 0%, rgba(58, 58, 210, 0) 80%)',
                    mixBlendMode: 'screen',
                    filter: 'blur(150px)',
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: 96,
                    display: 'flex',
                    alignItems: 'center',
                    px: 3,
                    py: 2,
                    backdropFilter: 'blur(10px)',
                    background:
                        'linear-gradient(rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.06) 100%)',
                }}
            >
                <BalanceBetLogo />
            </Box>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backdropFilter: 'blur(15px)',
                    flex: 1,
                    gap: 2,
                    zIndex: 10,
                }}
            >
                <FramerIllustration />
                {children}
            </Box>
            <Box sx={{ textAlign: 'center', pt: 2 }}>
                <Stack
                    spacing={{ xs: 2, sm: 4 }}
                    direction="row"
                    useFlexGap
                    sx={{ flexWrap: 'wrap', px: 2, pb: 2 }}
                >
                    <Link
                        href={ExternalDocsLinks.privacyPolicy}
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        href={ExternalDocsLinks.termsAndConditions}
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Terms & Conditions
                    </Link>
                    <Link
                        href={ExternalDocsLinks.contactUs}
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Contact Us
                    </Link>
                    <Link
                        href={ExternalDocsLinks.feedback}
                        sx={{ color: theme => theme.palette.primary[250] }}
                    >
                        Feedback
                    </Link>
                </Stack>
                <Divider />
                <Box sx={{ py: 3, px: 2 }}>
                    <Typography variant="body2" textAlign="left" pb={2}>
                        Copyright © 2024 Balance Bet - All Rights Reserved.
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1.5}>
                        <BrandLinkButton
                            component={Link}
                            target="_blank"
                            href={ExternalSocialLinks.instagram}
                        >
                            <InstagramLogo color="#3A3AD2" size={24} />
                        </BrandLinkButton>
                        <BrandLinkButton
                            component={Link}
                            target="_blank"
                            href={ExternalSocialLinks.twitter}
                        >
                            <XLogo color="#3A3AD2" size={24} />
                        </BrandLinkButton>
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
};
