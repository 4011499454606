// ClashDisplay fonts
import ClashDisplayVariableTff from '../../../../../fonts/clash-display/ClashDisplay-Variable.ttf';
import ClashDisplayVariableWoff from '../../../../../fonts/clash-display/ClashDisplay-Variable.woff';
import ClashDisplayVariableWoof2 from '../../../../../fonts/clash-display/ClashDisplay-Variable.woff2';

// Gilroy fonts
import GilroyLight from '../../../../../fonts/glory/Gilroy-Light.ttf';
import GilroyRegular from '../../../../../fonts/glory/Gilroy-Regular.ttf';
import GilroyMedium from '../../../../../fonts/glory/Gilroy-Medium.ttf';
import GilroyBold from '../../../../../fonts/glory/Gilroy-Bold.ttf';
import GilroyExtraBold from '../../../../../fonts/glory/Gilroy-ExtraBold.ttf';
// Inter fonts
import InterVariableFont from '../../../../../fonts/inter/Inter-VariableFont_opsz,wght.ttf';
import InterItalicVariableFont from '../../../../../fonts/inter/Inter-Italic-VariableFont_opsz,wght.ttf';

export const fontFaceDeclarations = `
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: url(${GilroyLight}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url(${GilroyRegular}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url(${GilroyMedium}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url(${GilroyBold}) format('truetype');
}
@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: url(${GilroyExtraBold}) format('truetype');
}
@font-face {
    font-family: 'ClashDisplay';
    src: url(${ClashDisplayVariableWoof2}) format('woff2'),
       url(${ClashDisplayVariableWoff}) format('woff'),
       url(${ClashDisplayVariableTff}) format('truetype');        
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url(${InterVariableFont}) format('truetype');
  font-weight: 100 900; /* Supports all weights from 100 to 900 */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(${InterItalicVariableFont}) format('truetype');
  font-weight: 100 900; /* Supports all weights from 100 to 900 */
  font-style: italic;
  font-display: swap;
}
`;
