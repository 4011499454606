import { inject, injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import {
    formStateValidators,
    formValidatorsWithCustomMessages,
} from '../../common/utils/form-state-validators';
import { AuthStore } from '../../common/stores/auth-store';
import { apiClient } from '../../common/api/api-client';
import {
    AuthenticationCodeModel,
    AvatarModel,
    AvatarTypeEnum,
    EmailPasswordPair,
    PlayerPrivacySetting,
    UserAddModel,
} from '../../common/api/api';
import { LoadStatus } from 'shared';

export enum SignupSteps {
    UsernameAvatar = 0,
    PersonalInfo = 1,
    Credentials = 2,
}

@injectable()
export class SignupStore {
    step: SignupSteps = SignupSteps.UsernameAvatar;
    availableAvatarFiles: AvatarModel[] = [];
    signupLoadStatus = LoadStatus.None;
    isValidating = false;

    usernameAvatarFormState = new FormState({
        username: new FieldState('').validators(
            formValidatorsWithCustomMessages.required('Username is required'),
            formValidatorsWithCustomMessages.username('Username is invalid'),
        ),
        avatarFile: new FieldState<AvatarModel>(
            new AvatarModel({
                id: 0,
                type: AvatarTypeEnum.Free,
                imageUrl: '',
            }),
        ),
    });

    personalInfoFormState = new FormState({
        fullName: new FieldState('').validators(
            formValidatorsWithCustomMessages.required('Full name is required'),
        ),
        phoneNumber: new FieldState('').validators(
            formValidatorsWithCustomMessages.required('Phone number is required'),
            formStateValidators.phoneNumber,
        ),
        privacySetting: new FieldState<PlayerPrivacySetting>(PlayerPrivacySetting.Public),
        agreeTerms: new FieldState(false).validators(value => (value ? false : 'Required')),
        olderThan18: new FieldState(false).validators(value => (value ? false : 'Required')),
    });

    credentialsFormState = new FormState({
        email: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.email,
        ),
        password: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.password,
        ),
    });

    get isLoading() {
        return this.signupLoadStatus === LoadStatus.Loading || this.isValidating;
    }

    get user() {
        const authenticationCode = new AuthenticationCodeModel({
            code: '',
            redirectUri: '',
        });

        const { email, password } = this.credentialsFormState.$;

        const emailPasswordPair = new EmailPasswordPair({
            email: email.value,
            password: password.value,
        });

        const { username, avatarFile } = this.usernameAvatarFormState.$;
        const { fullName, phoneNumber, privacySetting } = this.personalInfoFormState.$;

        return new UserAddModel({
            avatarId: avatarFile.$.id,
            fullName: fullName.value,
            phoneNumber: phoneNumber.value,
            username: username.value,
            privacySetting: privacySetting.value,
            authenticationCode,
            emailPasswordPair,
        });
    }

    get state() {
        return encodeURIComponent(
            JSON.stringify({
                u: this.user,
                s: this.step,
                t: 'signup',
            }),
        );
    }

    get isValid() {
        const forms = {
            [SignupSteps.UsernameAvatar]:
                this.usernameAvatarFormState.$.username.value &&
                this.usernameAvatarFormState.$.avatarFile.value.id,
            [SignupSteps.PersonalInfo]:
                this.personalInfoFormState.$.fullName.value &&
                this.personalInfoFormState.$.phoneNumber.value &&
                this.personalInfoFormState.$.agreeTerms.value &&
                this.personalInfoFormState.$.olderThan18.value,
            [SignupSteps.Credentials]:
                this.credentialsFormState.$.password.value &&
                this.credentialsFormState.$.email.value,
        };

        return forms[this.step];
    }

    @inject(AuthStore) private readonly authStore!: AuthStore;

    constructor() {
        makeAutoObservable(this);

        this.usernameAvatarFormState.disableAutoValidation();
        this.personalInfoFormState.disableAutoValidation();
        this.credentialsFormState.disableAutoValidation();
    }

    initFromState = (state: string, code: string | null, url: string) => {
        const { u, s } = JSON.parse(decodeURIComponent(state));

        this.step = +s;

        const { avatarId, fullName, phoneNumber, username, privacySetting, email, password } = u;

        this.usernameAvatarFormState.$.username.onChange(username);
        this.usernameAvatarFormState.$.avatarFile.onChange(
            this.availableAvatarFiles.find(avatar => avatar.id === avatarId) ||
                new AvatarModel({
                    id: 0,
                    type: AvatarTypeEnum.Free,
                    imageUrl: '',
                }),
        );

        this.personalInfoFormState.$.fullName.onChange(fullName);
        this.personalInfoFormState.$.phoneNumber.onChange(phoneNumber);
        this.personalInfoFormState.$.privacySetting.onChange(privacySetting);
        this.personalInfoFormState.$.agreeTerms.onChange(true);
        this.personalInfoFormState.$.olderThan18.onChange(true);

        this.credentialsFormState.$.email.onChange(email);
        this.credentialsFormState.$.password.onChange(password);

        if (code) {
            this.signup(new AuthenticationCodeModel({ code, redirectUri: url }));
        }
    };

    handleNextStep = async (step: SignupSteps) => {
        let isValid = false;

        runInAction(() => {
            this.isValidating = true;
        });

        switch (this.step) {
            case SignupSteps.UsernameAvatar:
                isValid = await this.validateUsernameAvatar();
                break;
            case SignupSteps.PersonalInfo:
                isValid = await this.validatePersonalInfo();
                break;
            case SignupSteps.Credentials:
                isValid = await this.validateCredentials();
                break;
        }

        runInAction(() => {
            this.isValidating = false;
        });

        if (isValid) {
            if (this.step === SignupSteps.Credentials) {
                await this.signup();
            } else {
                this.step = step;
            }
        }
    };

    handleSetStep = (step: SignupSteps) => {
        this.step = step;
    };

    signup = async (auth?: AuthenticationCodeModel) => {
        try {
            runInAction(() => {
                this.signupLoadStatus = LoadStatus.Loading;
            });

            const user = this.user;

            if (auth) {
                user.authenticationCode = auth;

                if (!user.emailPasswordPair?.password || !user.emailPasswordPair.email) {
                    user.emailPasswordPair = undefined;
                }
            }

            const jwtAuthResult = await apiClient.usersPOST(user);

            await this.authStore.authorize(jwtAuthResult);

            runInAction(() => {
                this.signupLoadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            runInAction(() => {
                this.signupLoadStatus = LoadStatus.Error;
            });
        }
    };

    private validateUsernameAvatar = async () => {
        await this.usernameAvatarFormState.enableAutoValidationAndValidate();

        const { username, avatarFile } = this.usernameAvatarFormState.$;

        // Validate username field
        const usernameValidation = await username.validate();
        if (usernameValidation.hasError) {
            return false;
        }

        // Check if the username is already in use
        const isUsernameUsed = await apiClient.checkUsernameValidity(username.value);
        if (isUsernameUsed) {
            username.setError('Username must be unique');
            return false;
        }

        // Validate avatar file
        if (!avatarFile.value.id) {
            username.setError('Avatar must be selected');
            return false;
        }

        return true;
    };

    private validatePersonalInfo = async () => {
        const { hasError } = await this.personalInfoFormState.enableAutoValidationAndValidate();

        if (hasError) {
            return false;
        }

        const { fullName, phoneNumber, agreeTerms, olderThan18 } = this.personalInfoFormState.$;

        // Validate fullName field
        const fullNameValidation = await fullName.validate();
        if (fullNameValidation.hasError) {
            return false;
        }

        // Validate phoneNumber field
        const phoneNumberValidation = await phoneNumber.validate();
        if (phoneNumberValidation.hasError) {
            return false;
        }

        // Validate agreeTerms field
        const agreeTermsValidation = await agreeTerms.validate();
        if (agreeTermsValidation.hasError || !agreeTerms.value) {
            agreeTerms.setError('You must agree to the terms');
            return false;
        }

        // Validate olderThan18 field
        const olderThan18Validation = await olderThan18.validate();
        if (olderThan18Validation.hasError || !olderThan18.value) {
            olderThan18.setError('You must be older than 18');
            return false;
        }

        return true;
    };

    private validateCredentials = async () => {
        const { hasError } = await this.credentialsFormState.enableAutoValidationAndValidate();

        return !hasError;
    };
}
