import { observer } from 'mobx-react';
import { TableColumnConfig } from '../../../common/utils/types';
import { GameModel } from '../../../common/api/api';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { formatDateWithName } from '../../../../utils';
import { Balance } from 'shared';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { provide, useDependencies } from 'ioc';
import { Fragment, useEffect } from 'react';
import { alpha, Stack, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { MyGamesListMobile } from './mobile/my-games-list-mobile';
import { noop } from '../../../common/utils/noop';
import { useConfirm } from 'material-ui-confirm';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { CLICKABLE_TABLE_ROW_SX, RANKED_GAME_BACKGROUND_COLOR } from '../../../common/utils/ui';
import { InvitePlayers } from '../../../invite-players/components/invite-players';
import ShareIcon from '@mui/icons-material/Share';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { MyGamesStore } from '../../stores/my-games-store';
import { useNavigate } from 'react-router-dom';
import { CrownSimple, DoorOpen, SquareHalf, UserPlus } from '@phosphor-icons/react';
import {
    customNegative,
    customNeutral,
    customPrimary,
    customSecondary,
} from '../../../redesigned-modules/root/components/theme-provider/colors';
import { ActionButton } from '../../../common/components/action-button/action-button';
import { SportSelectionDialog } from '../../../sport-selection/components/sport-selection-dialog';
import { MyGamesListStore } from '../../stores/my-games-list-store';

const myGamesColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: game => (
            <Stack direction="row" alignItems="center" spacing={1}>
                <Stack width={20} alignItems="center" justifyContent="center">
                    {game.isRanked && <CrownSimple size={20} weight="fill" color="#F0D36B" />}
                </Stack>
                <TypographyTableCell>#{game.id}</TypographyTableCell>
            </Stack>
        ),
    },
    {
        title: 'Start Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.startDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.endDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: game => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Current Balance',
        getComponent: game => (
            <Balance typographyProps={{ fontWeight: 700 }} amount={game.currentBalance} />
        ),
    },
    {
        title: 'Leagues',
        getComponent: game => <SportLeagueIconList leagues={game.leagues} />,
    },
];

export const MyGamesList = provide([MyGamesListStore])(
    observer(() => {
        const [
            {
                fetchGames,
                games,
                leaveGame,
                gamesLoadStatus,
                getShareLink,
                startInvitation,
                endInvitation,
                sendInvite,
                gameToShare,
                showSportSelection,
                hideSportSelection,
                gameToShowSportSelection,
            },
        ] = useDependencies(MyGamesListStore);

        useEffect(() => {
            fetchGames();
        }, []);

        const isMobile = useIsMobile();
        const confirm = useConfirm();

        const handleShare = (game: GameModel) => {
            startInvitation(game);
        };

        const navigate = useNavigate();

        const handleGameClick = (game: GameModel) => {
            navigate(`/my-games/content/${game.id}`);
        };

        const handleShowSportSelection = (game: GameModel) => {
            showSportSelection(game);
        };

        const handleLeave = (id: number) => {
            confirm({
                description: 'Are you sure you want to leave this game?',
                title: 'Leave Game',
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error',
                    fullWidth: true,
                },
                confirmationText: 'Yes, leave',
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'inherit',
                    fullWidth: true,
                },
            })
                .then(() => {
                    leaveGame(id);
                })
                .catch(noop);
        };

        return (
            <Fragment>
                <AsyncList length={games.length} loadStatus={gamesLoadStatus} onFetch={fetchGames}>
                    {setLastElement =>
                        isMobile ? (
                            <MyGamesListMobile
                                onLeave={handleLeave}
                                onShare={handleShare}
                                onGameClick={handleGameClick}
                                setLastElement={setLastElement}
                                onShowSportSelection={handleShowSportSelection}
                            />
                        ) : (
                            <CustomTable>
                                <TableBody>
                                    {games.map((game, gameIndex) => (
                                        <TableRow
                                            key={game.id}
                                            ref={
                                                gameIndex === games.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                            sx={{
                                                ...CLICKABLE_TABLE_ROW_SX,
                                                backgroundColor: game.isRanked
                                                    ? `${RANKED_GAME_BACKGROUND_COLOR} !important`
                                                    : undefined,
                                            }}
                                            onClick={() => {
                                                handleGameClick(game);
                                            }}
                                        >
                                            {myGamesColumns.map(column => (
                                                <TableCell key={column.title}>
                                                    {column.getComponent(game)}
                                                </TableCell>
                                            ))}
                                            <TableCell align="right">
                                                <Box
                                                    display="flex"
                                                    gap={1}
                                                    justifyContent="flex-end"
                                                >
                                                    <ActionButton
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            handleShowSportSelection(game);
                                                        }}
                                                        icon={<SquareHalf size={24} />}
                                                        variant="contained"
                                                        sx={{
                                                            background: customPrimary[350],
                                                            color: customNeutral[950],
                                                        }}
                                                    />
                                                    {!game.isRanked && (
                                                        <Fragment>
                                                            <ActionButton
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                    handleShare(game);
                                                                }}
                                                                sx={{
                                                                    background:
                                                                        customSecondary[300],
                                                                }}
                                                                color="secondary"
                                                                icon={
                                                                    <UserPlus
                                                                        size={24}
                                                                        weight="bold"
                                                                    />
                                                                }
                                                                variant="contained"
                                                            />
                                                            <ActionButton
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                    handleLeave(game.id);
                                                                }}
                                                                sx={{
                                                                    color: customNegative[500],
                                                                }}
                                                                color="error"
                                                                icon={
                                                                    <DoorOpen
                                                                        size={24}
                                                                        weight="fill"
                                                                    />
                                                                }
                                                                variant="text"
                                                            />
                                                        </Fragment>
                                                    )}
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
                {!!gameToShare && (
                    <InvitePlayers
                        onInvite={sendInvite}
                        onClose={endInvitation}
                        getShareLink={getShareLink}
                    />
                )}
                {!!gameToShowSportSelection && (
                    <SportSelectionDialog
                        onClose={hideSportSelection}
                        gameId={gameToShowSportSelection.id}
                        onStartBetting={() => handleGameClick(gameToShowSportSelection)}
                    />
                )}
            </Fragment>
        );
    }),
);
