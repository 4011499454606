import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { MyGamesStore } from '../stores/my-games-store';
import MyGamesSectionImage from '../../../assets/redesign/images/my-games-section-image.png';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CreateGame } from '../../create-game/components/create-game';
import { useState } from 'react';
import { NotificationsStore } from '../../common/stores/notifications-store';
import { GameFullModel } from '../../common/api/api';
import { GameContentPage } from './content/game-content-page';
import { asParentPath } from '../../common/utils/as-parent-path';
import { WithBettingHub } from '../../root/components/with-betting-hub';
import { AppContentContainer } from '../../redesigned-modules/common/components/app-content-container/app-content-container';
import { SectionImage } from '../../common/components/section-image-container/section-image';
import { MyGamesList } from './my-games-list/my-games-list';

const MyGamesPageMain = provide([MyGamesStore])(
    observer(() => {
        const [{ isCreatingGame, showCreateGame, hideCreateGame }, { notify }] = useDependencies(
            MyGamesStore,
            NotificationsStore,
        );

        const [gamesListKey, setGamesListKey] = useState(() => Math.random());

        const createGameCallback = (game?: GameFullModel) => {
            setGamesListKey(Math.random());

            if (game) {
                notify({
                    severity: 'success',
                    children: <Typography>Successfully created! ID: #{game.id}.</Typography>,
                });
            }
        };

        return (
            <AppContentContainer
                header={<SectionImage src={MyGamesSectionImage} headline="Placeholder" />}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'start',
                        gap: 3,
                        mb: 3,
                    }}
                >
                    <Typography variant="h4" fontWeight={900}>
                        My Games
                    </Typography>
                    <Button
                        onClick={showCreateGame}
                        variant="contained"
                        sx={{ width: { xs: '100%', sm: '344px' } }}
                    >
                        Create Game
                    </Button>
                </Box>
                <MyGamesList key={gamesListKey} />
                {isCreatingGame && (
                    <CreateGame onClose={hideCreateGame} successCallback={createGameCallback} />
                )}
            </AppContentContainer>
        );
    }),
);

export const MyGamesPage = () => {
    return (
        <Routes>
            <Route path="" element={<MyGamesPageMain />} />
            <Route
                path={asParentPath('content/:gameId')}
                element={
                    <Routes>
                        <Route
                            path=":leagueId?/:matchId?"
                            element={
                                <WithBettingHub>
                                    <GameContentPage />
                                </WithBettingHub>
                            }
                        />
                        <Route path="*" element={<Navigate to="" replace />} />
                    </Routes>
                }
            />
            <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
    );
};
