import { FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import { AuthMobileLayout } from './auth-mobile-layout';
import { AuthDesktopLayout } from './auth-desktop-layout';

export const AuthLayout: FC<PropsWithChildren> = ({ children, ...rest }) => {
    const { isDesktop } = useScreenSize();

    return isDesktop ? (
        <AuthDesktopLayout {...rest}>
            <Outlet />
        </AuthDesktopLayout>
    ) : (
        <AuthMobileLayout {...rest}>
            <Outlet />
        </AuthMobileLayout>
    );
};
