import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { provide, useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { StyledFormFrame } from '../brand-components/brand-form-frame';
import { SuccessfullySentBanner } from '../brand-components/successfully-sent-banner';
import { ContactStore } from '../../stores/contact.store';
import { ContactUsForm } from './contact-us-form';
import Link from '@mui/material/Link';
import { UnauthenticatedRoutes } from '../../../redesigned-modules/root/components/app/app-unauthenticated';

export const ContactUs = provide([ContactStore])(
    observer(() => {
        const [{ isMessageSent }] = useDependencies(ContactStore);

        return (
            <StyledFormFrame>
                {isMessageSent ? (
                    <SuccessfullySentBanner title="Thank you!">
                        <Typography
                            textAlign="center"
                            sx={{ color: theme => theme.palette.primary[250], mx: { xs: 0 } }}
                        >
                            We will contact as soon as possible. Every customer's opinion is
                            important for us.{' '}
                            <Link
                                to={UnauthenticatedRoutes.login}
                                component={RouterLink}
                                textAlign="center"
                                underline="hover"
                                sx={{ color: theme => theme.palette.primary[250] }}
                            >
                                Log in now
                            </Link>
                        </Typography>
                    </SuccessfullySentBanner>
                ) : (
                    <ContactUsForm />
                )}
            </StyledFormFrame>
        );
    }),
);
