import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from 'shared';
import { RankedGameWithParticipantModel } from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';

@injectable()
export class RankedGamesStore {
    loadStatus = LoadStatus.None;
    areAllGamesFetched = false;
    games: RankedGameWithParticipantModel[] = [];
    gameToShowSportSelection?: (typeof this.games)[number];

    constructor() {
        makeAutoObservable(this);
    }

    fetchGames = async () => {
        if (this.loadStatus === LoadStatus.Loading || this.areAllGamesFetched) {
            return;
        }

        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const { items: games, totalAmount } = await apiClient.getRankedGames(
                undefined,
                undefined,
                this.games.length,
                ASYNC_LOAD_LIMIT,
            );

            runInAction(() => {
                this.games.push(...games);
            });

            if (totalAmount <= this.games.length) {
                this.areAllGamesFetched = true;
            }

            runInAction(() => {
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };

    showSportSelection = (game: typeof this.gameToShowSportSelection) => {
        this.gameToShowSportSelection = game;
    };

    hideSportSelection = () => {
        this.gameToShowSportSelection = undefined;
    };

    register = async (game: (typeof this.games)[number]) => {
        try {
            const participant = await apiClient.registerForRankedGame(game.id);

            const gameInList = this.games.find(({ id }) => id === game.id)!;
            gameInList.participant = participant;

            runInAction(() => {
                this.games = this.games.slice();
            });
        } catch {
            // skip
        }
    };

    leave = async (game: (typeof this.games)[number]) => {
        try {
            await apiClient.cancelRankedGameRegistration(game.id);

            const gameInList = this.games.find(({ id }) => id === game.id)!;
            gameInList.participant = undefined;

            runInAction(() => {
                this.games = this.games.slice();
            });
        } catch {
            // skip
        }
    };
}
