import Box from '@mui/material/Box';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LobbyGames } from '../lobby-games/lobby-games';
import { Participants } from '../../../participants/components/participants';
import { CreateGame } from '../../../create-game/components/create-game';
import { LobbyStore } from '../../stores/lobby-store';
import { observer } from 'mobx-react';
import LobbySectionImage from '../../../../assets/images/lobby-section-image.png';
import { useDependencies, provide } from 'ioc';
import Link from '@mui/material/Link';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { GameFullModel } from '../../../common/api/api';
import { InvitedGame } from '../invited-game/invited-game';
import { SectionImage } from '../../../common/components/section-image-container/section-image';
import { AppContentContainer } from '../../../redesigned-modules/common/components/app-content-container/app-content-container';

export const LobbyPage = provide([LobbyStore])(
    observer(() => {
        const [
            {
                gameForParticipants,
                showCreateGame,
                isCreatingGame,
                hideCreateGame,
                initInvitedGame,
                invitedGame,
                hideParticipants,
                getParticipants,
            },
            { notify },
        ] = useDependencies(LobbyStore, NotificationsStore);

        const [searchParams] = useSearchParams();

        useEffect(() => {
            // TODO: have globally defined keys
            const invitationId = searchParams.get('invitationId');
            const token = searchParams.get('token');

            if (invitationId) {
                initInvitedGame(+invitationId, token ? decodeURIComponent(token) : undefined);
            }
        }, []);

        const navigate = useNavigate();

        const createGameCallback = (game?: GameFullModel) => {
            if (game) {
                notify({
                    severity: 'success',
                    children: (
                        <Typography>
                            Successfully created! ID: #{game.id}. See&nbsp;
                            <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    // closeNotification();
                                    navigate('/my-games/upcoming');
                                }}
                            >
                                Upcoming Games
                            </Link>
                            .
                        </Typography>
                    ),
                });
            }
        };

        return (
            <AppContentContainer
                header={<SectionImage src={LobbySectionImage} headline="PLAYER vs PLAYER" />}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        gap: 3,
                        mb: 3,
                    }}
                >
                    <Typography variant="h4" fontWeight={900}>
                        Games
                    </Typography>
                    <Button
                        onClick={showCreateGame}
                        variant="contained"
                        sx={{ width: { xs: '100%', sm: '344px' } }}
                    >
                        Create Game
                    </Button>
                </Box>
                <LobbyGames />
                {isCreatingGame && (
                    <CreateGame successCallback={createGameCallback} onClose={hideCreateGame} />
                )}
                {!!invitedGame && <InvitedGame game={invitedGame} />}
                {!!gameForParticipants && (
                    <Participants onClose={hideParticipants} getParticipants={getParticipants} />
                )}
            </AppContentContainer>
        );
    }),
);
