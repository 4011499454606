import { inject, injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from 'shared';
import { apiClient } from '../../common/api/api-client';
import { RankInfoModel, SubRankInfoModel } from '../../common/api/api';
import { AuthStore } from '../../common/stores/auth-store';

@injectable()
export class RankedPlayStore {
    loadStatus = LoadStatus.None;
    allRanks: RankInfoModel[] = [];
    currentSubRank?: SubRankInfoModel;
    nextSubRank?: SubRankInfoModel;

    @inject(AuthStore) private readonly authStore!: AuthStore;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const allRanks = await apiClient.getAllRanks();

            let currentSubRank: SubRankInfoModel | undefined;
            allRanks.find(rank => {
                const subRank = rank.subRanks.find(
                    subRank => subRank.name === this.authStore.me?.rankInfo?.name,
                );

                if (subRank) {
                    currentSubRank = subRank;

                    return true;
                }

                return false;
            });

            const currentMaxScore = this.authStore.me?.rankInfo?.maxScore;

            if (currentMaxScore !== undefined) {
                const nextSubRank = await apiClient.getNextRank(currentMaxScore);

                runInAction(() => {
                    this.nextSubRank = nextSubRank;
                });
            }

            runInAction(() => {
                this.allRanks = allRanks;
                this.currentSubRank = currentSubRank;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
