import { GameLeaguePublicModel } from '../../../api/api';
import { forwardRef, Fragment, ReactElement } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { CardActionArea, CardActionAreaProps, Chip, Stack } from '@mui/material';
import { SportLeagueIconList } from '../../sport-league-icon-list/sport-league-icon-list';
import { Participants } from '../../participants/participants';
import { formatDateWithName } from '../../../../../utils';
import { customNeutral } from '../../../../redesigned-modules/root/components/theme-provider/colors';

interface GameLeagueListItemMobileProps {
    league: GameLeaguePublicModel;
    actions?: ReactElement;
    onClick?: CardActionAreaProps['onClick'];
}

export const GameLeagueListItemMobile = forwardRef<HTMLDivElement, GameLeagueListItemMobileProps>(
    ({ actions, onClick, league }, ref) => {
        const padding = `12px 20px`;

        let content = (
            <Fragment>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
                    <Typography fontWeight={700} fontSize={20}>
                        {league.name}
                    </Typography>
                    <Typography fontWeight={700} fontSize={14}>
                        {formatDateWithName(league.startDate)}
                    </Typography>
                </Stack>
                <Box mb={1}>
                    <Participants>
                        <Typography fontWeight={700} fontSize={14}>
                            {league.currentPlayerCount}/{league.maxPlayerCount} Participants
                        </Typography>
                    </Participants>
                </Box>
                <SportLeagueIconList leagues={league.leagues} hideLongList={false} />
                {!!actions && <Box mt={1.5}>{actions}</Box>}
                <Typography
                    fontSize={12}
                    fontWeight={500}
                    textAlign="end"
                    color="textSecondary"
                    mt={actions ? 1 : 1.5}
                >
                    Ends on: {formatDateWithName(league.endDate)}
                </Typography>
            </Fragment>
        );

        if (onClick) {
            content = (
                <CardActionArea sx={{ padding }} onClick={onClick}>
                    {content}
                </CardActionArea>
            );
        }

        return (
            <Card
                ref={ref}
                sx={{
                    boxShadow: 2,
                    border: `1px solid ${customNeutral[700]}`,
                    padding: onClick ? undefined : padding,
                }}
            >
                {content}
            </Card>
        );
    },
);
