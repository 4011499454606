import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { ContactStore } from '../../stores/contact.store';
import { BrandTypography } from '../brand-components/brand-typography';
import { BrandTextField } from '../brand-components/brand-text-field';
import { BrandButton } from '../brand-components/brand-button';
import { LoadStatus } from '../../../common/enums/load-status';
import { ArrowCircleRight } from '@phosphor-icons/react';

export const ContactUsForm = observer(() => {
    const [{ formState, isSendingMessage, handleSendEmail }] = useDependencies(ContactStore);
    const { fullName, message, email } = formState.$;

    const handleSubmit = (event: any) => {
        event.preventDefault();
        handleSendEmail();
    };

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
            }}
            component="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            width="100%"
        >
            <Box sx={{ mb: { xs: 2 } }}>
                <BrandTypography variant="h4" textAlign="center" mb={2}>
                    Contact us
                </BrandTypography>
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <BrandTextField
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    variant="standard"
                    placeholder="Full Name"
                    autoComplete="name"
                    value={fullName.value}
                    error={fullName.hasError}
                    onChange={event => {
                        fullName.onChange(event.target.value);
                    }}
                    onBlur={fullName.enableAutoValidationAndValidate}
                    helperText={fullName.error}
                />
                <BrandTextField
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    variant="standard"
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    value={email.value}
                    error={email.hasError}
                    onChange={event => {
                        email.onChange(event.target.value);
                    }}
                    onBlur={email.enableAutoValidationAndValidate}
                    helperText={email.error}
                />
                <BrandTextField
                    sx={{ mb: 3 }}
                    required
                    fullWidth
                    variant="standard"
                    type="text"
                    minRows={3}
                    multiline
                    placeholder="Message"
                    autoComplete="test"
                    value={message.value}
                    error={message.hasError}
                    onChange={event => {
                        message.onChange(event.target.value);
                    }}
                    onBlur={message.enableAutoValidationAndValidate}
                    helperText={message.error}
                />
            </Box>

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <BrandButton
                    type="submit"
                    disabled={formState.hasError || isSendingMessage === LoadStatus.Loading}
                    endIcon={<ArrowCircleRight size={32} weight="fill" color="#000" />}
                >
                    Send
                </BrandButton>
            </Box>
        </Box>
    );
});
