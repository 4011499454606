import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from 'shared';
import { apiClient } from '../../common/api/api-client';
import { GameModel, SportSelectionLeagueModel } from '../../common/api/api';

@injectable()
export class SportSelectionStore {
    loadStatus = LoadStatus.None;
    selections: SportSelectionLeagueModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init = async (id: GameModel['id']) => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            const selections = await apiClient.getSelectionForMatch(id);

            runInAction(() => {
                this.selections = selections;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
