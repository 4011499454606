import Box from '@mui/material/Box';
import { BalanceBetLogo, HeaderLogoMobile } from '../logo/balance-bet-logo';
import { Stack } from '@mui/material';
import { Link as RouterLink, NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { UserNotifications } from '../../../../user-notifications/components/user-notifications';
import IconButton from '@mui/material/IconButton';
import { List as ListIcon } from '@phosphor-icons/react';
import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { usePopover } from '../../../../common/hooks/use-popover';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import { AppStore } from '../../../../common/stores/app-store';
import { Profile } from './profile';

const xsHeight = 58;
const smHeight = 96;

export const Header = observer(() => {
    const [{ setHeaderHeight }] = useDependencies(AppStore);

    const navItems = [
        { title: 'Lobby', path: '/lobby' },
        { title: 'Leagues', path: '/leagues' },
        { title: 'Slate', path: '/slate' },
        { title: 'My Games', path: '/my-games' },
        { title: 'Ranked Play', path: '/ranked-play' },
        { title: 'History', path: '/history' },
    ];

    const {
        handleOpen: handleDrawerOpen,
        handleClose: handleDrawerClose,
        open: isDrawerOpen,
    } = usePopover();

    const navigate = useNavigate();

    const { isMobile } = useScreenSize();

    const headerElRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!headerElRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                setHeaderHeight(entry.borderBoxSize[0].blockSize);
            });
        });

        resizeObserver.observe(headerElRef.current);

        return () => resizeObserver.disconnect();
    }, [headerElRef.current]);

    const drawer = (
        <Drawer open={isDrawerOpen} onClose={handleDrawerClose}>
            <Box sx={{ textAlign: 'center', width: 250 }}>
                <Typography variant="h6" sx={{ my: 2 }}>
                    Sports Betting
                </Typography>
                <Divider />
                <List>
                    {navItems.map(item => (
                        <ListItem
                            key={item.title}
                            disablePadding
                            onClick={() => {
                                navigate(item.path);
                                handleDrawerClose();
                            }}
                        >
                            <ListItemButton sx={{ textAlign: 'center' }}>
                                <ListItemText primary={item.title} sx={{ fontWeight: 'bold' }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    );

    return (
        <Box
            ref={headerElRef}
            component="nav"
            sx={{
                position: 'fixed',
                top: 0,
                px: {
                    md: 10,
                },
                width: '100%',
                zIndex: 100,
            }}
        >
            <Box
                sx={{
                    height: {
                        xs: xsHeight,
                        sm: smHeight,
                    },
                    borderBottomRightRadius: {
                        xs: 0,
                        sm: 40,
                    },
                    borderBottomLeftRadius: {
                        xs: 0,
                        sm: 40,
                    },
                    boxShadow: '0px 0px 32px 10px #FFFFFF26 inset',
                    backdropFilter: 'blur(40px)',
                    px: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                    },
                    py: {
                        xs: 1,
                        sm: 3,
                    },
                }}
            >
                <Stack
                    justifyContent="space-between"
                    direction="row"
                    alignItems="center"
                    height="100%"
                >
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box
                            sx={{
                                display: {
                                    md: 'none',
                                },
                            }}
                        >
                            <IconButton onClick={handleDrawerOpen}>
                                <ListIcon color="#fff" size={32} />
                            </IconButton>
                            {drawer}
                        </Box>
                        <Link component={RouterLink} to="/" sx={{ display: 'flex' }}>
                            {isMobile ? <HeaderLogoMobile /> : <BalanceBetLogo />}
                        </Link>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Stack
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'block',
                                },
                            }}
                            direction="row"
                            spacing={1.5}
                        >
                            {navItems.map(({ title, path }) => {
                                return (
                                    <RouterNavLink key={title} to={path}>
                                        {({ isActive }) => (
                                            <Button
                                                color={isActive ? 'secondary' : undefined}
                                                sx={{
                                                    'color': isActive ? undefined : '#ffffff',
                                                    'fontWeight': 700,
                                                    ':hover': {
                                                        background: '#4c5b66',
                                                    },
                                                }}
                                            >
                                                {title}
                                            </Button>
                                        )}
                                    </RouterNavLink>
                                );
                            })}
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={{
                                md: 3,
                            }}
                            alignItems="center"
                        >
                            <UserNotifications />
                            <Profile />
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
});
