// User information form Full name phone number playerVisibility terms and conditions and confirmation I'm +18
import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { SignupStore } from '../../../stores/signup.store';

import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Radio,
    RadioGroup,
} from '@mui/material';
import { BrandMuiTelInput, BrandTextField } from '../../brand-components/brand-text-field';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { PlayerPrivacySetting } from '../../../../common/api/api';
import { ChangeEvent } from 'react';

export const UserInfoForm = observer(() => {
    const [{ personalInfoFormState }] = useDependencies(SignupStore);
    const { fullName, phoneNumber, privacySetting, agreeTerms, olderThan18 } =
        personalInfoFormState.$;

    const handlePrivacySettingChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as PlayerPrivacySetting;
        privacySetting.onChange(value);
    };

    return (
        <Box gap={2} display="flex" flexDirection="column">
            <BrandTextField
                fullWidth
                error={!!fullName.error}
                helperText={fullName.error}
                variant="standard"
                placeholder="Full Name"
                value={fullName.value}
                onChange={event => {
                    fullName.onChange(event.target.value);
                }}
            />
            <BrandMuiTelInput
                defaultCountry="US"
                variant="standard"
                value={phoneNumber.value}
                error={!!phoneNumber.error}
                helperText={phoneNumber.error}
                placeholder="Phone Number"
                onChange={phoneNumber.onChange}
            />
            <FormControl>
                <Typography color="#F7F8F8">Player profile visibility</Typography>
                <RadioGroup row value={privacySetting.value} onChange={handlePrivacySettingChange}>
                    <FormControlLabel
                        value={PlayerPrivacySetting.Public}
                        control={
                            <Radio
                                sx={{
                                    'color': '#58F081',
                                    '&.Mui-checked': { color: '#58F081' },
                                }}
                            />
                        }
                        label="Public"
                    />
                    <FormControlLabel
                        value={PlayerPrivacySetting.Private}
                        control={
                            <Radio
                                sx={{
                                    'color': '#58F081',
                                    '&.Mui-checked': { color: '#58F081' },
                                }}
                            />
                        }
                        label="Private"
                    />
                    <FormControlLabel
                        value={PlayerPrivacySetting.Friends}
                        control={
                            <Radio
                                sx={{
                                    'color': '#58F081',
                                    '&.Mui-checked': { color: '#58F081' },
                                }}
                            />
                        }
                        label="Friends Only"
                    />
                </RadioGroup>
            </FormControl>
            <FormControl error={!!agreeTerms.error || !!olderThan18.error}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => agreeTerms.onChange(!agreeTerms.value)}
                            checked={agreeTerms.value}
                            sx={{
                                'color': '#58F081',
                                '&.Mui-checked': { color: '#58F081' },
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2" fontSize={12} color="#A5B7F0">
                            I have read and agree to the{' '}
                            <Link
                                sx={{
                                    fontWeight: 'bold',
                                    color: theme => theme.palette.primary[250],
                                }}
                            >
                                Terms and Conditions
                            </Link>{' '}
                            and{' '}
                            <Link
                                sx={{
                                    fontWeight: 'bold',
                                    color: theme => theme.palette.primary[250],
                                }}
                            >
                                Privacy Policy
                            </Link>
                        </Typography>
                    }
                />
                {agreeTerms.error && (
                    <FormHelperText>
                        Please agree to the terms and conditions and privacy policy
                    </FormHelperText>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={() => olderThan18.onChange(!olderThan18.value)}
                            checked={olderThan18.value}
                            sx={{
                                'color': '#58F081',
                                '&.Mui-checked': { color: '#58F081' },
                            }}
                        />
                    }
                    label={
                        <Typography variant="body2" fontSize={12} color="#A5B7F0">
                            I confirm that I am 18+ Years Old
                        </Typography>
                    }
                />
                {olderThan18.error && (
                    <FormHelperText>Please confirm that you are 18+ years old</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
});
