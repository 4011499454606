import { FC } from 'react';
import { LeagueTitleModel } from '../../api/api';
import Box from '@mui/material/Box';
import { alpha, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

interface ISportLeagueIconListProps {
    leagues?: LeagueTitleModel[];
    hideLongList?: boolean;
}

export const SportLeagueIconList: FC<ISportLeagueIconListProps> = ({
    leagues = [],
    hideLongList = true,
}) => {
    const leaguesToShow = hideLongList ? leagues.slice(0, 4) : leagues;

    return (
        <Stack spacing={0.5} direction="row">
            {leaguesToShow.map(league => {
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height={{ xs: 24, md: 40 }}
                        width={{ xs: 24, md: 40 }}
                        p={{ xs: '2px', md: '4px' }}
                        key={league.id}
                    >
                        <img
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            alt={league.name}
                            src={league.logoUrl!}
                        />
                    </Box>
                );
            })}
            {leaguesToShow.length < leagues.length && (
                <Tooltip
                    title={
                        <Typography sx={{ whiteSpace: 'pre-line' }} align="center">
                            {leagues
                                .slice(4)
                                .map(i => i.name)
                                .join('\n')}
                        </Typography>
                    }
                >
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        height={{ xs: 24, md: 40 }}
                        width={{ xs: 24, md: 40 }}
                        sx={{
                            background: alpha('#ffffff', 0.1),
                            borderRadius: 200,
                        }}
                    >
                        <Typography fontWeight={700}>
                            +{leagues.length - leaguesToShow.length}
                        </Typography>
                    </Stack>
                </Tooltip>
            )}
        </Stack>
    );
};
