import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

export function DiscordCallback() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const stateParam = searchParams.get('state');
        const queryParams = searchParams.toString();

        if (stateParam) {
            try {
                const { t } = JSON.parse(decodeURIComponent(stateParam));
                if (t === 'signup') {
                    navigate(`/signup?${queryParams}`);
                } else {
                    navigate(`/login?${queryParams}`);
                }
            } catch (error) {
                window.toastr.error('Invalid state parameter');
            }
        }
    }, [searchParams, navigate]);

    return <p>Processing Discord authentication...</p>;
}
