import { ButtonProps, SxProps } from '@mui/material';
import { FC, ReactElement } from 'react';
import Button from '@mui/material/Button';

interface IActionButtonProps extends ButtonProps {
    icon?: ReactElement;
}

export const ActionButton: FC<IActionButtonProps> = props => {
    const { sx, children, icon } = props;

    const newSx: SxProps = { ...sx, borderRadius: '8px' };

    if (icon) {
        newSx.p = '8px';
        newSx.minWidth = 'unset';
    }

    return (
        <Button {...props} sx={newSx}>
            {icon ? icon : children}
        </Button>
    );
};
