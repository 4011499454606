import { FC } from 'react';
import { MatchWithOddsModel } from '../../api/api';
import { formatDateWithName, setLocalTime } from '../../../../utils';
import { Box, Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';
import { MatchOverviewRow, MatchOverviewRowProps } from './match-overview-row';
import { BetSlipMatchInfo } from '../../../bet-slip/stores/bet-slip-store';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CaretRight } from '@phosphor-icons/react';
import { LiveClock } from './live-clock';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { LiveOddsStore } from '../../stores/live-odds-store';

export interface MatchOverviewProps {
    matchOverview: MatchWithOddsModel;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];
}

export const MatchOverview: FC<MatchOverviewProps> = observer(({ matchOverview, onMatchClick }) => {
    const [{ matchInfo }] = useDependencies(LiveOddsStore);

    const liveMatchInfo = matchInfo.get(matchOverview.match.id);

    const betSlipMatchInfo: BetSlipMatchInfo = {
        id: matchOverview.match.id,
        teams: matchOverview.match.teams,
    };

    const day = formatDateWithName(matchOverview.match.startDate, true);

    const theme = useTheme();

    const { columnHeaders } = matchOverview.odds;

    return (
        <BetSlipMatchContext.Provider value={betSlipMatchInfo}>
            <Box>
                <Grid container spacing={0.5}>
                    <Grid size={5} />
                    <Grid size={7} container spacing={0.5}>
                        {columnHeaders.map(columnHeader => (
                            <Grid
                                size={Math.round(12 / columnHeaders.length)}
                                key={columnHeader}
                                alignContent="end"
                            >
                                <Typography
                                    color="textSecondary"
                                    fontWeight={600}
                                    fontSize={14}
                                    textAlign="center"
                                    sx={{ wordBreak: 'break-word' }}
                                >
                                    {columnHeader}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                {matchOverview.odds.rows?.length === 1 ? (
                    <MatchOverviewRow
                        grouped
                        onMatchClick={onMatchClick}
                        matchOverview={matchOverview}
                        currentOdds={matchOverview.odds.rows![0].odds!}
                    />
                ) : (
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        {matchOverview.match.teams?.map((team, index) => {
                            return (
                                <MatchOverviewRow
                                    onMatchClick={onMatchClick}
                                    key={team.id}
                                    matchOverview={matchOverview}
                                    teamIndex={index}
                                    currentOdds={matchOverview.odds.rows![index].odds!}
                                />
                            );
                        })}
                    </Box>
                )}
                <Grid container spacing={0.5} sx={{ mt: 0.5 }}>
                    <Grid size={{ xs: 12, md: 5 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            {matchOverview.match.startDate.getTime() <= Date.now() ||
                            liveMatchInfo?.clock !== undefined ? (
                                <LiveClock clock={liveMatchInfo?.clock} />
                            ) : (
                                <Typography fontSize={12} color="textSecondary">
                                    {day} {setLocalTime(matchOverview.match.startDate)}
                                </Typography>
                            )}
                            <Button
                                onClick={() => onMatchClick(matchOverview)}
                                color="inherit"
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontWeight: 600,
                                }}
                                endIcon={<CaretRight size={16} />}
                            >
                                More Wagers
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </BetSlipMatchContext.Provider>
    );
});
