import { FC, PropsWithChildren, ReactElement } from 'react';
import { Container, Stack } from '@mui/material';

interface IAppContentContainerProps {
    header?: ReactElement;
}

export const AppContentContainer: FC<PropsWithChildren<IAppContentContainerProps>> = ({
    children,
    header,
}) => {
    return (
        <Stack sx={{ pt: { xs: header ? 0 : 2, sm: 2 }, pb: 2, height: '100%' }}>
            {!!header && (
                <Container maxWidth="xl" sx={{ px: { xs: 0, md: 10 }, mb: 4, flexShrink: 0 }}>
                    {header}
                </Container>
            )}
            <Container maxWidth="xl" sx={{ px: { xs: 2, sm: 3, md: 10 }, flexGrow: 1 }}>
                {children}
            </Container>
        </Stack>
    );
};

export const AppContentPictureContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: 10 } }}>
            {children}
        </Container>
    );
};
