import { injectable } from 'ioc';
import { makeAutoObservable } from 'mobx';

import { FieldState, FormState } from 'formstate';
import { formValidatorsWithCustomMessages } from '../../common/utils/form-state-validators';
import { LoadStatus } from '../../common/enums/load-status';
import { apiClient } from '../../common/api/api-client';
import { CustomerMessageModel } from '../../common/api/api';

@injectable()
export class ContactStore {
    isMessageSent = false;
    isSendingMessage = LoadStatus.None;

    formState = new FormState({
        fullName: new FieldState('').validators(
            formValidatorsWithCustomMessages.required('Full name is required'),
        ),
        message: new FieldState('').validators(),
        email: new FieldState('').validators(
            formValidatorsWithCustomMessages.email(
                'Please enter a valid email address (e.g., name@example.com)',
            ),
        ),
    });

    constructor() {
        makeAutoObservable(this);
        this.formState.disableAutoValidation();
    }

    handleSendEmail = async () => {
        try {
            this.isSendingMessage = LoadStatus.Loading;

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                return;
            }

            const payload: any = new CustomerMessageModel({
                fullName: this.formState.$.fullName.value,
                message: this.formState.$.message.value,
                email: this.formState.$.email.value,
            });

            // need to add api client
            await apiClient.contacts(payload);
            // contacts

            this.isMessageSent = true;
            this.isSendingMessage = LoadStatus.Ok;
        } catch {
            this.isSendingMessage = LoadStatus.Error;
        }
    };
}
