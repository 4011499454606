import { alpha } from '@mui/material';

export const TOOLBAR_HEIGHT = 72;
export const TRIAL_BANNER_HEIGHT = 40;

export const CLICKABLE_TABLE_ROW_SX = {
    'cursor': 'pointer',
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.15) !important',
    },
};

export const RANKED_GAME_BACKGROUND_COLOR = alpha('#18D46D', 0.1);

export const getAppBarHeight = (isSubscriptionInfoShown: boolean) => {
    return TOOLBAR_HEIGHT + (isSubscriptionInfoShown ? TRIAL_BANNER_HEIGHT : 0);
};

export const disabledOddOpacity = 0.3;
