import { observer } from 'mobx-react';
import { motion } from 'framer-motion';
import { SignupStore } from '../../../stores/signup.store';
import { useDependencies } from 'ioc';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { BrandTextField } from '../../brand-components/brand-text-field';
import { AvatarDialog } from './avatar-dialog';
import { FieldCriteria } from '../../brand-components/password-criteria';
import ActiveProfileImage from '../../assets/active-profile.png';
import DiceImage from '../../assets/dice.png';
import { AvatarStore } from '../../../stores/avatar.store';
import { LoadStatus } from 'shared';
import { ColoredBorder } from '../../../../common/components/colored-border/colored-border';

// Must be between 4-20 characters long
// Can include letters, numbers, and underscores
// No special characters or spaces allowed
// Username must be unique
const criteria = [
    {
        label: 'Must be between 4-20 characters long',
        test: (value: string) => value.length >= 4 && value.length <= 20,
    },
    {
        label: 'Can include letters, numbers, and underscores',
        test: (value: string) => /^[a-zA-Z0-9_]*$/.test(value),
    },
    {
        label: 'No special characters or spaces allowed',
        test: (value: string) => /^[a-zA-Z0-9_]*$/.test(value),
    },
];

export const UserPersonaForm = observer(() => {
    const [
        { usernameAvatarFormState },
        { initAvatarStore, handleOpenAvatarModal, getRandomAvatar, loadRandomAvatarStatus },
    ] = useDependencies(SignupStore, AvatarStore);
    const { username, avatarFile } = usernameAvatarFormState.$;

    const handleSetRandomAvatar = async () => {
        const avatar = await getRandomAvatar();
        avatarFile.onChange(avatar!);
    };

    const handleOpenModal = () => {
        initAvatarStore();
        handleOpenAvatarModal();
    };

    return (
        <Box>
            <Box alignItems="center" display="flex" flexDirection="column" mb={1}>
                <Typography variant="h6" mb={1}>
                    Choose your avatar
                </Typography>
                <Box position="relative" justifyContent="center" display="flex">
                    <ColoredBorder borderRadius={100} borderColor="#000" overflow="hidden">
                        <motion.div
                            key={avatarFile.value.imageUrl}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 50 }}
                            transition={{ duration: 0.4, ease: 'easeOut' }}
                        >
                            <Avatar
                                onClick={handleOpenModal}
                                sx={{
                                    cursor: 'pointer',
                                    width: 100,
                                    height: 100,
                                    border: '3px',
                                    backgroundColor: '#000',
                                    borderImage: 'linear-gradient(to bottom, #00C853, #191A2A)',
                                }}
                                src={avatarFile.value.imageUrl || ActiveProfileImage}
                            />
                        </motion.div>
                    </ColoredBorder>
                    <IconButton
                        onClick={handleSetRandomAvatar}
                        loading={loadRandomAvatarStatus === LoadStatus.Loading}
                        sx={{
                            width: 40,
                            height: 40,
                            position: 'absolute',
                            backgroundColor: '#081217',
                            transform: 'translate(40px, 55px)',
                        }}
                    >
                        <img alt="" width="150%" height="auto" src={DiceImage} />
                    </IconButton>
                </Box>
            </Box>
            <BrandTextField
                fullWidth
                variant="standard"
                placeholder="Username"
                value={username.value}
                error={!!username.error}
                onChange={event => {
                    username.onChange(event.target.value);
                }}
            />
            <FieldCriteria criteria={criteria} field={username.value} />
            <FieldCriteria
                field={username.value}
                criteria={[
                    {
                        label: username.error as string,
                        test: (value: string) => !(value && username.error),
                    },
                ]}
            />
            <AvatarDialog />
        </Box>
    );
});
