import { FC, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import Box from '@mui/material/Box';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import { LastElementSetter } from '../../../../common/utils/types';
import { GameModel } from '../../../../common/api/api';
import { ActionButton } from '../../../../common/components/action-button/action-button';
import { DoorOpen, SquareHalf, UserPlus } from '@phosphor-icons/react';
import {
    customNegative,
    customNeutral,
    customPrimary,
    customSecondary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { MyGamesListStore } from '../../../stores/my-games-list-store';

interface UpcomingGamesMobileProps {
    setLastElement: LastElementSetter;
    onLeave: (gameId: number) => void;
    onShare: (game: GameModel) => void;
    onGameClick: (game: GameModel) => void;
    onShowSportSelection: (game: GameModel) => void;
}

export const MyGamesListMobile: FC<UpcomingGamesMobileProps> = observer(
    ({ setLastElement, onLeave, onGameClick, onShare, onShowSportSelection }) => {
        const [{ games }] = useDependencies(MyGamesListStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => (
                    <GameListItemMobile
                        key={game.id}
                        ref={index === games.length - 1 ? setLastElement : undefined}
                        onClick={() => {
                            onGameClick(game);
                        }}
                        game={game}
                        hideInitialBalance
                        actions={
                            <Box display="flex" gap={1}>
                                <ActionButton
                                    onClick={event => {
                                        event.stopPropagation();
                                        onShowSportSelection(game);
                                    }}
                                    endIcon={<SquareHalf size={24} />}
                                    variant="contained"
                                    sx={{
                                        background: customPrimary[350],
                                        color: customNeutral[950],
                                        flexGrow: 1,
                                    }}
                                >
                                    Matches
                                </ActionButton>
                                {!game.isRanked && (
                                    <Fragment>
                                        <ActionButton
                                            onClick={event => {
                                                event.stopPropagation();
                                                onShare(game);
                                            }}
                                            sx={{
                                                background: customSecondary[300],
                                                flexGrow: 1,
                                            }}
                                            color="secondary"
                                            endIcon={<UserPlus size={24} weight="bold" />}
                                            variant="contained"
                                        >
                                            Invite
                                        </ActionButton>
                                        <ActionButton
                                            onClick={event => {
                                                event.stopPropagation();
                                                onLeave(game.id);
                                            }}
                                            sx={{
                                                color: customNegative[500],
                                            }}
                                            color="error"
                                            icon={<DoorOpen size={24} weight="fill" />}
                                            variant="text"
                                        />
                                    </Fragment>
                                )}
                            </Box>
                        }
                    />
                ))}
            </Box>
        );
    },
);
