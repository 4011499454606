export function timeAgo(then: Date) {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - then.getTime()) / 1000);

    if (seconds < 60) {
        return `${seconds}sec`;
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        return `${minutes}min`;
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
        return `${hours}hour`;
    }
    const days = Math.floor(hours / 24);
    if (days < 7) {
        return `${days}day`;
    }
    const weeks = Math.floor(days / 7);
    if (weeks < 4) {
        return `${weeks}week`;
    }
    const months = Math.floor(days / 30);
    if (months < 12) {
        return `${months}month`;
    }
    const years = Math.floor(days / 365);
    return `${years}year`;
}
