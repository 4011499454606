import { inject, injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import { AuthStore } from '../../common/stores/auth-store';
import { TokenResponse } from '@react-oauth/google';
import { apiClient } from '../../common/api/api-client';
import { AuthenticationCodeModel } from '../../common/api/api';
import { getRedirectUriForGoogle } from '../utils/third-party-utils';
import { SignupStore } from './signup.store';

export enum AvatarFilterTypes {
    MostPopular = 'mostPopular',
    Animals = 'animals',
    Users = 'users',
    Premium = 'premium',
    Sport = 'sport',
}

@injectable()
export class SocialSignupStore {
    loginGoogleLoadStatus = LoadStatus.None;
    loginDiscordLoadStatus = LoadStatus.None;
    loginAppleLoadStatus = LoadStatus.None;

    @inject(AuthStore) private readonly authStore!: AuthStore;
    @inject(SignupStore) private readonly signupStore!: SignupStore;

    constructor() {
        makeAutoObservable(this);
    }

    handleDiscordSignup = () => {
        try {
            runInAction(() => {
                this.loginDiscordLoadStatus = LoadStatus.Loading;
            });
        } finally {
            // TODO: Implement the Discord signup logic
        }
    };

    handleGoogleSignup = async (
        tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>,
    ) => {
        try {
            const payload = new AuthenticationCodeModel({
                code: tokenResponse.access_token,
                redirectUri: getRedirectUriForGoogle(),
            });

            await this.signupStore.signup(payload);
        } catch (error: any) {
            window.toastr.error(
                error?.message || 'An error occurred while trying to sign up with Google',
            );
        }
    };

    handleAppleSignup = async () => {};
}
