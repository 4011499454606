import { FC } from 'react';
import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { UserNotificationsStore } from '../stores/user-notifications-store';
import { UserNotification } from './user-notification';
import { LoadStatus } from '../../common/enums/load-status';

interface UserNotificationsContentProps {
    onClose?: () => void;
}

export const UserNotificationsContent: FC<UserNotificationsContentProps> = observer(() => {
    const [{ userNotifications, loadStatus }] = useDependencies(UserNotificationsStore);
    const isMobile = useIsMobile();

    return (
        <Box
            sx={{
                borderRadius: '8px',
                border: 'solid transparent',
                borderWidth: '1px',
                background:
                    'linear-gradient(#000D1B, #000D1B) padding-box, linear-gradient(to right, #2B5FD9, #58F081) border-box',
            }}
        >
            <Box display="flex" alignItems="center" px={2} sx={{ height: 68 }}>
                <Typography fontWeight={700} fontSize={20} fontFamily="Inter">
                    Notifications
                </Typography>
            </Box>
            <Box
                sx={{
                    px: 2,
                    py: 1,
                    maxHeight: '80vh',
                    height: 'calc(100% - 68px)',
                    overflow: 'auto',
                    width: isMobile ? undefined : 360,
                }}
            >
                {loadStatus === LoadStatus.Loading || !userNotifications.length ? (
                    <Box
                        height="100%"
                        minHeight={200}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {loadStatus === LoadStatus.Loading ? (
                            <CircularProgress />
                        ) : (
                            <Typography>You have no notifications</Typography>
                        )}
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" gap={2}>
                        {userNotifications.map((notification, i) => (
                            <UserNotification
                                key={notification.id}
                                notification={notification}
                                index={i}
                            />
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
});
