import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { FC } from 'react';
import { observer } from 'mobx-react';
import { LobbyStore } from '../../../stores/lobby-store';
import { useDependencies } from 'ioc';
import { useTheme } from '@mui/material';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { CustomIconButton } from '../../../../common/components/custom-icon-button/custom-icon-button';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import { GameModel } from '../../../../common/api/api';
import { LastElementSetter } from '../../../../common/utils/types';
import { ActionButton } from '../../../../common/components/action-button/action-button';
import { SquareHalf } from '@phosphor-icons/react';
import {
    customNeutral,
    customPrimary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';

interface ILobbyGamesMobileProps {
    onJoinGame: (game: GameModel['id']) => void;
    onShowParticipants: (game: GameModel) => void;
    onShowSportSelection: (game: GameModel) => void;
    setLastElement: LastElementSetter;
}

export const LobbyGamesMobile: FC<ILobbyGamesMobileProps> = observer(
    ({ setLastElement, onJoinGame, onShowParticipants, onShowSportSelection }) => {
        const [{ games }] = useDependencies(LobbyStore);

        const theme = useTheme();

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => {
                    return (
                        <GameListItemMobile
                            game={game}
                            key={game.id}
                            ref={index === games.length - 1 ? setLastElement : undefined}
                            actions={
                                <Box display="flex" gap={1}>
                                    <ActionButton
                                        onClick={() => onShowSportSelection(game)}
                                        endIcon={<SquareHalf size={24} />}
                                        variant="contained"
                                        sx={{
                                            background: customPrimary[350],
                                            color: customNeutral[950],
                                        }}
                                    >
                                        Matches
                                    </ActionButton>
                                    <ActionButton
                                        onClick={() => onShowParticipants(game)}
                                        icon={<Groups2OutlinedIcon />}
                                        variant="contained"
                                        sx={{
                                            background: customNeutral[350],
                                            color: customNeutral[950],
                                        }}
                                    />
                                    <ActionButton
                                        onClick={() => onJoinGame(game.id)}
                                        endIcon={<DirectionsWalkIcon />}
                                        color="primary"
                                        variant="contained"
                                        sx={{ flexGrow: 1 }}
                                    >
                                        Join
                                    </ActionButton>
                                </Box>

                                // <Box display="flex" gap={2}>
                                //     <CustomIconButton
                                //         onClick={() => showParticipants(game)}
                                //         color={theme.palette.grey[800]}
                                //     >
                                //         <Groups2OutlinedIcon />
                                //     </CustomIconButton>
                                //     <Button
                                //         onClick={() => {
                                //             onJoinGame(game.id);
                                //         }}
                                //         endIcon={<DirectionsWalkIcon />}
                                //         fullWidth
                                //         sx={{
                                //             border: '1px solid',
                                //             boxShadow: 1,
                                //         }}
                                //     >
                                //         <Typography>Join</Typography>
                                //     </Button>
                                // </Box>
                            }
                        />
                    );
                })}
            </Box>
        );
    },
);
