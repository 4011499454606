import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { SignupSteps, SignupStore } from '../../stores/signup.store';
import { BrandTypography } from '../brand-components/brand-typography';
import { GradientStepper } from './components/signup-stepper';
import { BrandButton } from '../brand-components/brand-button';
import { ArrowCircleRight } from '@phosphor-icons/react';
import { UserInfoForm } from './components/user-info-form';
import { UserPersonaForm } from './components/user-persona-form';
import { UserEmailPasswordForm } from './components/user-email-password-form';

const formComponents = {
    [SignupSteps.UsernameAvatar]: UserPersonaForm,
    [SignupSteps.PersonalInfo]: UserInfoForm,
    [SignupSteps.Credentials]: UserEmailPasswordForm,
};

const buttonLabel = {
    [SignupSteps.UsernameAvatar]: 'Next',
    [SignupSteps.PersonalInfo]: 'Next',
    [SignupSteps.Credentials]: 'Sign up',
};

export const SignupFormAdvanced = observer(() => {
    const [{ handleNextStep, step, isLoading, isValid }] = useDependencies(SignupStore);

    const handleNext = () => {
        switch (step) {
            case SignupSteps.UsernameAvatar:
                handleNextStep(SignupSteps.PersonalInfo);
                break;
            case SignupSteps.PersonalInfo:
                handleNextStep(SignupSteps.Credentials);
                break;
            case SignupSteps.Credentials:
                handleNextStep(SignupSteps.Credentials);
                break;
        }
    };

    const CurrentForm = formComponents[step];

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flex: 1,
            }}
            display="flex"
            flexDirection="column"
            width="100%"
        >
            <Box sx={{ mb: { xs: 2 } }}>
                <BrandTypography variant="h4" textAlign="center" mb={2}>
                    Sign up
                </BrandTypography>
                <Typography
                    textAlign="center"
                    sx={{ color: theme => theme.palette.primary[250], mb: 2 }}
                >
                    Already have an account?{' '}
                    <Link
                        sx={{ color: theme => theme.palette.primary[250] }}
                        underline="hover"
                        fontWeight="600"
                        component={RouterLink}
                        to="/login"
                    >
                        Log in
                    </Link>{' '}
                </Typography>
                <GradientStepper />
            </Box>
            <CurrentForm />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <BrandButton
                    disabled={!isValid}
                    loading={isLoading}
                    onClick={handleNext}
                    endIcon={<ArrowCircleRight size={32} weight="fill" color="#000" />}
                >
                    {buttonLabel[step]}
                </BrandButton>
            </Box>
        </Box>
    );
});
