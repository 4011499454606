import { FC } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDependencies } from 'ioc';
import { HistoryStore } from '../../../stores/history-store';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import { LastElementSetter } from '../../../../common/utils/types';
import { GameModel } from '../../../../common/api/api';
import { SquareHalf } from '@phosphor-icons/react';
import {
    customNeutral,
    customPrimary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { ActionButton } from '../../../../common/components/action-button/action-button';

interface IHistoryGamesMobileProps {
    setLastElement: LastElementSetter;
    onShowLeaderboard: (game: GameModel) => void;
    onShowSportSelection: (game: GameModel) => void;
}

export const HistoryGamesMobile: FC<IHistoryGamesMobileProps> = observer(
    ({ setLastElement, onShowLeaderboard, onShowSportSelection }) => {
        const [{ games }] = useDependencies(HistoryStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => {
                    return (
                        <GameListItemMobile
                            ref={index === games.length - 1 ? setLastElement : undefined}
                            key={game.id}
                            game={game}
                            actions={
                                <Box display="flex" gap={1}>
                                    <ActionButton
                                        onClick={() => onShowSportSelection(game)}
                                        endIcon={<SquareHalf size={24} />}
                                        variant="contained"
                                        sx={{
                                            background: customPrimary[350],
                                            color: customNeutral[950],
                                        }}
                                    >
                                        Matches
                                    </ActionButton>
                                    <Button
                                        onClick={() => {
                                            onShowLeaderboard(game);
                                        }}
                                        startIcon={<LeaderboardIcon />}
                                        fullWidth
                                        sx={{
                                            border: '1px solid #FF7D00',
                                            color: '#FF7D00',
                                            boxShadow: 1,
                                        }}
                                    >
                                        <Typography>Leaderboard</Typography>
                                    </Button>
                                </Box>
                            }
                        />
                    );
                })}
            </Box>
        );
    },
);
