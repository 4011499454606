import { injectable } from 'ioc';
import { makeAutoObservable } from 'mobx';
import { LoadStatus } from 'shared';
import { apiClient } from '../../common/api/api-client';
import { AvatarModel, IAvatarCategoryModel } from '../../common/api/api';

@injectable()
export class AvatarStore {
    open = false;
    loadAvatarsStatus = LoadStatus.None;
    loadRandomAvatarStatus = LoadStatus.None;
    loadAvatarCategoriesStatus = LoadStatus.None;

    selectedCategoryId = 0;

    avatars: AvatarModel[] = [];
    randomAvatar?: AvatarModel = undefined;
    avatarCategories: IAvatarCategoryModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    initAvatarStore = async () => {
        await this.getAvatarCategories();

        const firstCategoryId = this.avatarCategories[0].id;

        await this.getAvatars(firstCategoryId);
    };

    handleOpenAvatarModal = () => (this.open = true);

    handleCloseAvatarModal = () => (this.open = false);

    handleChangeCategory = async (categoryId: number) => {
        this.selectedCategoryId = categoryId;
        await this.getAvatars(categoryId);
    };

    getRandomAvatar = async () => {
        try {
            this.loadRandomAvatarStatus = LoadStatus.Loading;

            const response = await apiClient.random();

            this.randomAvatar = response;

            return response;
        } catch (error: any) {
            window.toastr.error(error?.message || 'Failed to load random avatar');
        } finally {
            this.loadRandomAvatarStatus = LoadStatus.Error;
        }
    };

    private getAvatars = async (categoryId: number) => {
        try {
            this.loadAvatarsStatus = LoadStatus.Loading;

            const response = await apiClient.getAvatars(1, 100, categoryId);

            this.avatars = response.items;
        } catch (error: any) {
            window.toastr.error(error?.message || 'Failed to load avatars');
        } finally {
            this.loadAvatarsStatus = LoadStatus.Error;
        }
    };

    private getAvatarCategories = async () => {
        try {
            this.loadAvatarCategoriesStatus = LoadStatus.Loading;

            const response = await apiClient.getAvatarCategories();

            this.avatarCategories = response;

            return response;
        } catch (error: any) {
            window.toastr.error(error?.message || 'Failed to load avatar categories');
        } finally {
            this.loadAvatarCategoriesStatus = LoadStatus.Error;
        }
    };
}
