import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { formatDateWithName } from '../../../../utils';
import { Balance } from 'shared';
import Box from '@mui/material/Box';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { Fragment, useEffect } from 'react';
import { LobbyGamesMobile } from './mobile/lobby-games-mobile';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { GameModel } from '../../../common/api/api';
import { noop } from '../../../common/utils/noop';
import { observer } from 'mobx-react';
import { LobbyStore } from '../../stores/lobby-store';
import { useDependencies } from 'ioc';
import { TableColumnConfig } from '../../../common/utils/types';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { ActionButton } from '../../../common/components/action-button/action-button';
import { SquareHalf } from '@phosphor-icons/react';
import {
    customNeutral,
    customPrimary,
} from '../../../redesigned-modules/root/components/theme-provider/colors';
import { SportSelectionDialog } from '../../../sport-selection/components/sport-selection-dialog';

const lobbyColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: game => <TypographyTableCell>#{game.id}</TypographyTableCell>,
    },
    {
        title: 'Start Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.startDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.endDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: game => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Initial Balance',
        getComponent: game => (
            <Balance typographyProps={{ fontWeight: 600 }} amount={game.initialBalance} />
        ),
    },
    {
        title: 'Leagues',
        getComponent: game => <SportLeagueIconList leagues={game.leagues} />,
    },
];

export const LobbyGames = observer(() => {
    const [
        {
            showParticipants,
            games,
            joinGame,
            gamesLoadStatus,
            fetchGames,
            showSportSelection,
            hideSportSelection,
            gameToShowSportSelection,
        },
        { notify },
    ] = useDependencies(LobbyStore, NotificationsStore);

    const isMobile = useIsMobile();
    const navigate = useNavigate();

    useEffect(() => {
        fetchGames();
    }, []);

    const handleShowSportSelection = (game: GameModel) => {
        showSportSelection(game);
    };

    const handleJoinGame = (gameId: GameModel['id']) => {
        joinGame(gameId)
            .then(game => {
                notify({
                    severity: 'info',
                    children: (
                        <Fragment>
                            <Typography mr={2} variant="body1">
                                Joined game #{game.id}!
                            </Typography>
                            <Typography variant="body2">
                                You can find the game in&nbsp;
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        // closeNotification();
                                        navigate('/my-games');
                                    }}
                                >
                                    My Games
                                </Link>
                                &nbsp;page.
                            </Typography>
                        </Fragment>
                    ),
                });
            })
            .catch(noop);
    };

    return (
        <Fragment>
            <AsyncList length={games.length} loadStatus={gamesLoadStatus} onFetch={fetchGames}>
                {setLastElement =>
                    isMobile ? (
                        <LobbyGamesMobile
                            onJoinGame={handleJoinGame}
                            setLastElement={setLastElement}
                            onShowParticipants={showParticipants}
                            onShowSportSelection={handleShowSportSelection}
                        />
                    ) : (
                        <CustomTable>
                            <TableBody>
                                {games.map((game, gameIndex) => (
                                    <TableRow
                                        key={game.id}
                                        ref={
                                            gameIndex === games.length - 1
                                                ? setLastElement
                                                : undefined
                                        }
                                    >
                                        {lobbyColumns.map(column => (
                                            <TableCell key={column.title}>
                                                {column.getComponent(game)}
                                            </TableCell>
                                        ))}
                                        <TableCell align="right">
                                            <Box display="flex" gap={1} justifyContent="flex-end">
                                                <ActionButton
                                                    onClick={() => handleShowSportSelection(game)}
                                                    icon={<SquareHalf size={24} />}
                                                    variant="contained"
                                                    sx={{
                                                        background: customPrimary[350],
                                                        color: customNeutral[950],
                                                    }}
                                                />
                                                <ActionButton
                                                    onClick={() => showParticipants(game)}
                                                    icon={<Groups2OutlinedIcon />}
                                                    variant="contained"
                                                    sx={{
                                                        background: customNeutral[350],
                                                        color: customNeutral[950],
                                                    }}
                                                />
                                                <ActionButton
                                                    onClick={() => handleJoinGame(game.id)}
                                                    endIcon={<DirectionsWalkIcon />}
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    Join
                                                </ActionButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </CustomTable>
                    )
                }
            </AsyncList>
            {!!gameToShowSportSelection && (
                <SportSelectionDialog
                    onClose={hideSportSelection}
                    gameId={gameToShowSportSelection.id}
                />
            )}
        </Fragment>
    );
});
