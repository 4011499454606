import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { SubRankInfoModel } from '../../common/api/api';
import Box from '@mui/material/Box';
import { customNeutral } from '../../redesigned-modules/root/components/theme-provider/colors';

interface IRankProps {
    rank: SubRankInfoModel;
    useNumericName?: boolean;
}

export const Rank: FC<IRankProps> = ({ rank, useNumericName }) => {
    return (
        <Stack alignItems="center" spacing={1}>
            <Box
                sx={{
                    width: 56,
                    height: 56,
                }}
            >
                <img src={rank.logoUrl} style={{ width: '100%', height: '100%' }} />
            </Box>
            <Typography color={customNeutral[200]} fontWeight={600}>
                {useNumericName ? rank.nameNumericPart : rank.name}
            </Typography>
        </Stack>
    );
};
