import { Stepper, Step, StepLabel, StepConnector, stepConnectorClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDependencies } from 'ioc';
import { observer } from 'mobx-react';
import { SignupSteps, SignupStore } from '../../../stores/signup.store';

const StepIconWrapper = styled('div')({
    position: 'relative',
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const GradientCircle = styled('div')<any>(({ active }) => ({
    'width': 32,
    'height': 32,
    'borderRadius': '50%',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'color': 'black',
    'fontFamily': 'ClashDisplay',
    'border': '3px solid transparent',
    'backgroundClip': 'padding-box',
    'position': 'relative',
    'zIndex': 1,
    'textShadow': active ? '0px 1px 3px #4CFF88, 0px 1px 3px #4CFF88' : '#0011',
    'fontWeight': 700,
    '-webkit-text-stroke-color': '#58F081',
}));

const GradientBorder = styled('div')<any>(({ active }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    padding: '3px', // Adjust thickness
    background: active ? 'linear-gradient(180deg, #5BE734 0%, #2B5FD9 100%)' : '#000',
    WebkitMask: 'linear-gradient(white, white) content-box, linear-gradient(white, white)',
    maskComposite: 'exclude',
    zIndex: 0,
}));

const GradientConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: '50%',
        transform: 'translateY(-50%)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(95deg, #5BE734 0%, #2B5FD9 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(95deg, #5BE734 0%, #2B5FD9 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        marginLeft: 4,
        marginRight: 4,
        backgroundColor: '#000',
        border: 'none',
        ...theme.applyStyles?.('dark', {
            backgroundColor: '#000',
        }),
    },
}));

const steps = [
    { step: SignupSteps.UsernameAvatar },
    { step: SignupSteps.PersonalInfo },
    { step: SignupSteps.Credentials },
];

export const GradientStepper = observer(() => {
    const [{ step, handleSetStep }] = useDependencies(SignupStore);

    const handleBackStep = (upcomingStep: SignupSteps) => {
        if (upcomingStep <= step) {
            handleSetStep(upcomingStep);
        }
    };

    return (
        <Stepper activeStep={step} alternativeLabel connector={<GradientConnector />}>
            {steps.map((item, index) => (
                <Step
                    sx={{
                        cursor: item.step < step ? 'pointer' : 'default',
                    }}
                    key={item.step}
                    onClick={() => handleBackStep(item.step)}
                >
                    <StepLabel
                        slots={{
                            stepIcon: () => (
                                <StepIconWrapper>
                                    <GradientCircle active={index <= +step}>
                                        {index + 1}
                                    </GradientCircle>
                                    <GradientBorder active={index <= +step} />
                                </StepIconWrapper>
                            ),
                        }}
                    />
                </Step>
            ))}
        </Stepper>
    );
});
