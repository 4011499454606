import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { SportSelectionStore } from '../stores/sport-selection-store';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { DialogActions, Grid2 as Grid, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ColorfulDivider } from '../../redesigned-modules/common/components/colorful-divider/colorful-divider';
import Card from '@mui/material/Card';
import { GameModel } from '../../common/api/api';
import { useScreenSize } from '../../common/hooks/use-is-mobile';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { formatDateWithName } from '../../../utils';
import { useTeamNameFormatter } from '../../common/hooks/use-team-name-formatter';

interface ISportSelectionDialogProps {
    onStartBetting?: () => void;
    onClose: () => void;
    gameId: GameModel['id'];
}

export const SportSelectionDialog: FC<ISportSelectionDialogProps> = provide([SportSelectionStore])(
    observer(({ onStartBetting, onClose, gameId }) => {
        const [{ init, selections }] = useDependencies(SportSelectionStore);

        useEffect(() => {
            init(gameId);
        }, []);

        const formatTeamName = useTeamNameFormatter();

        const { isMobile, isDesktop } = useScreenSize();

        return (
            <Dialog
                maxWidth={isDesktop ? 'md' : 'sm'}
                fullScreen={isMobile}
                open
                onClose={onClose}
                fullWidth
            >
                <DialogTitle sx={{ m: 0, px: 3, py: 2 }}>
                    <Typography fontWeight={600} fontSize={32}>
                        All Matches
                    </Typography>
                </DialogTitle>
                <IconButton
                    onClick={onClose}
                    sx={{ p: 0, position: 'absolute', top: 16, right: 24 }}
                >
                    <CloseIcon sx={{ width: 32, height: 32 }} />
                </IconButton>
                <DialogContent>
                    {selections.map(selection => (
                        <Stack key={selection.leagueInfo.id} mb={2}>
                            <Typography fontSize={24}>{selection.leagueInfo.name}</Typography>
                            <ColorfulDivider height={4} my={2} />
                            <Grid container spacing={2}>
                                {selection.matches.map(match => (
                                    <Grid key={match.id} size={isDesktop ? 6 : 12}>
                                        <Card sx={{ px: 3, py: 2 }}>
                                            <Stack spacing={2} mb={1.5}>
                                                {match.teams.map(team => (
                                                    <Stack
                                                        key={team.id}
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <img
                                                            height={32}
                                                            width={32}
                                                            src={team.logoUrl}
                                                            alt={team.name}
                                                        />
                                                        <Typography fontWeight={700}>
                                                            {formatTeamName(team)}
                                                        </Typography>
                                                    </Stack>
                                                ))}
                                            </Stack>
                                            <Typography
                                                color="textSecondary"
                                                fontWeight={600}
                                                fontSize={14}
                                            >
                                                {formatDateWithName(match.startDate)}
                                            </Typography>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    ))}
                </DialogContent>
                {!!onStartBetting && (
                    <DialogActions sx={{ px: 3, py: 2 }}>
                        <Button variant="contained" onClick={onStartBetting}>
                            Start Betting
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    }),
);
