import { observer } from 'mobx-react';
import { provide } from 'ioc';
import { HistoryStore } from '../../stores/history-store';
import { Fragment, useEffect } from 'react';
import HistorySectionImage from '../../../../assets/images/history-section-image.jpeg';
import Typography from '@mui/material/Typography';
import { HistoryGames } from '../history-games/history-games';
import { useDependencies } from 'ioc';
import { Leaderboard } from '../../../leaderboard/components/leaderboard';
import { AppContentContainer } from '../../../redesigned-modules/common/components/app-content-container/app-content-container';
import { SectionImage } from '../../../common/components/section-image-container/section-image';

export const HistoryPage = provide([HistoryStore])(
    observer(() => {
        const [{ winStats, initHistory, gameIdForLeaderboard, hideLeaderboard }] =
            useDependencies(HistoryStore);

        useEffect(() => {
            initHistory();
        }, []);

        return (
            <AppContentContainer
                header={
                    <SectionImage
                        src={HistorySectionImage}
                        headline={
                            winStats ? `${winStats.headToHeadWon}:${winStats.headToHeadPlayed}` : ''
                        }
                    />
                }
            >
                <Typography mb={3} variant="h4" fontWeight={900}>
                    History
                </Typography>
                <HistoryGames />
                {gameIdForLeaderboard !== undefined && (
                    <Leaderboard gameId={gameIdForLeaderboard} onClose={hideLeaderboard} />
                )}
            </AppContentContainer>
        );
    }),
);
