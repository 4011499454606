import { forwardRef, Fragment, ReactElement } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatDateWithName } from '../../../../../utils';
import { RankedGameWithParticipantModel } from '../../../../common/api/api';
import { CardActionArea, Stack } from '@mui/material';
import { Participants } from '../../../../common/components/participants/participants';
import { customNeutral } from '../../../../redesigned-modules/root/components/theme-provider/colors';
import { SportLeagueIconList } from '../../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { Balance } from 'shared';

interface IRankedGameListItemMobileProps {
    game: RankedGameWithParticipantModel;
    actions: ReactElement;
    onClick?: CardProps['onClick'];
}

export const RankedGameListItemMobile = forwardRef<HTMLDivElement, IRankedGameListItemMobileProps>(
    ({ actions, onClick, game }, ref) => {
        const padding = `12px 20px`;

        let content: ReactElement = (
            <Fragment>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1.5}>
                    <Typography fontWeight={700} fontSize={20}>
                        Game #{game.id}
                    </Typography>
                    <Typography fontWeight={700} fontSize={14}>
                        {formatDateWithName(game.timeRange.startDate)}
                    </Typography>
                </Stack>
                <Stack direction="row" mb={1} alignItems="center" spacing={2}>
                    <Balance
                        typographyProps={{ fontWeight: 600 }}
                        amount={game.participant?.balance ?? game.initialBalance}
                    />
                </Stack>
                <SportLeagueIconList leagues={game.leagues} hideLongList={false} />
                {!!actions && <Box mt={1.5}>{actions}</Box>}
                <Typography
                    fontSize={12}
                    fontWeight={500}
                    textAlign="end"
                    color="textSecondary"
                    mt={actions ? 1 : 1.5}
                >
                    Ends on: {formatDateWithName(game.timeRange.endDate)}
                </Typography>
            </Fragment>
        );

        if (onClick) {
            content = (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <CardActionArea sx={{ padding }} onClick={onClick}>
                    {content}
                </CardActionArea>
            );
        }

        return (
            <Card
                ref={ref}
                sx={{
                    boxShadow: 2,
                    border: `1px solid ${customNeutral[700]}`,
                    padding: onClick ? undefined : padding,
                }}
            >
                {content}
            </Card>
        );
    },
);
