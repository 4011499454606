import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';

interface PasswordCriteriaProps {
    password: string;
}

interface CriteriaProps {
    field: string;
    criteria: { label: string; test: (field: string) => boolean }[];
}

const criteria = [
    {
        label: 'Up to 100 characters',
        test: (password: string) => password.length <= 100 && password.length !== 0,
    },
    { label: 'At least 8 characters long', test: (password: string) => password.length >= 8 },
    {
        label: 'Includes letters, numbers & symbols',
        test: (password: string) =>
            /[A-Za-z]/.test(password) &&
            /\d/.test(password) &&
            /[!@#$%^&*(),.?":{}|<>]/.test(password),
    },
    {
        label: 'Avoid common words for better security',
        test: (password: string) =>
            !/password|123456|qwerty|letmein/.test(password.toLowerCase()) && password.length > 0,
    },
];

export const PasswordCriteria: FC<PasswordCriteriaProps> = ({ password }) => {
    const theme = useTheme();

    return (
        <Box sx={{ mb: 2 }}>
            {criteria.map(criterion => {
                const isEmpty = password.length === 0;
                const isValid = criterion.test(password);

                const color = isEmpty
                    ? theme.palette.tertiary[700]
                    : isValid
                      ? theme.palette.secondary.main
                      : theme.palette.error.main;

                const Icon = isValid ? (
                    <CheckIcon htmlColor={color} sx={{ fontSize: 16, mr: 1 }} />
                ) : (
                    <CircleIcon htmlColor={color} sx={{ fontSize: 10, mr: 1 }} />
                );

                return (
                    <Typography
                        key={criterion.label}
                        sx={{
                            color,
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'color 0.1s ease',
                        }}
                    >
                        {Icon}
                        {criterion.label}
                    </Typography>
                );
            })}
        </Box>
    );
};

export const FieldCriteria: FC<CriteriaProps> = ({ field, criteria }) => {
    const theme = useTheme();

    return (
        <Box>
            {criteria.map(criterion => {
                const isEmpty = field.length === 0;
                const isValid = criterion.test(field);

                const color = isEmpty
                    ? theme.palette.tertiary[700]
                    : isValid
                      ? theme.palette.secondary.main
                      : theme.palette.error.main;

                const Icon = isValid ? (
                    <CheckIcon htmlColor={color} sx={{ fontSize: 16, mr: 1 }} />
                ) : (
                    <CircleIcon htmlColor={color} sx={{ fontSize: 10, mr: 1 }} />
                );

                return (
                    <Typography
                        key={criterion.label}
                        sx={{
                            color,
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'color 0.1s ease',
                        }}
                    >
                        {criterion.label ? Icon : null}
                        {criterion.label}
                    </Typography>
                );
            })}
        </Box>
    );
};
