import { observer } from 'mobx-react';
import { useDependencies } from 'ioc';
import { GameContentStore } from '../../stores/game-content-store';
import Button from '@mui/material/Button';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';

export const GameContentActions = observer(() => {
    const [{ showLeaderboard, showBetHistory }] = useDependencies(GameContentStore);

    const theme = useTheme();

    return (
        <Box display="flex" gap={2} width="100%">
            <Button
                variant="outlined"
                color="inherit"
                fullWidth
                size="large"
                onClick={() => {
                    showBetHistory();
                }}
                sx={{ color: theme.palette.primary.main }}
            >
                <Typography fontWeight={700}>Bet History</Typography>
            </Button>
            <Button
                variant="outlined"
                color="inherit"
                fullWidth
                size="large"
                endIcon={<LeaderboardIcon />}
                onClick={showLeaderboard}
                sx={{ color: '#FF7D00' }}
            >
                <Typography fontWeight={700}>Leaderboard</Typography>
            </Button>
        </Box>
    );
});
