import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { Stack, Tab, Tabs } from '@mui/material';
import { AppContentContainer } from '../../redesigned-modules/common/components/app-content-container/app-content-container';
import { provide, useDependencies } from 'ioc';
import { RankedPlayStore } from '../stores/ranked-play-store';
import { useEffect } from 'react';
import { Link as RouterLink, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { RankedPlayRoutes } from '../utils/ranked-play-routes';
import { asParentPath } from '../../common/utils/as-parent-path';
import { RankedPlayProfile } from './ranked-play-profile';
import { RankedGames } from './ranked-games/ranked-games';
import { useScreenSize } from '../../common/hooks/use-is-mobile';
import { AllRanks } from './all-ranks/all-ranks';
import { GlobalLeaderboard } from './global-leaderboard/global-leaderboard';

const RankedPlayPageMain = provide([RankedPlayStore])(
    observer(() => {
        const [{ init }] = useDependencies(RankedPlayStore);

        const { tab } = useParams();

        useEffect(() => {
            init();
        }, []);

        const { isDesktop } = useScreenSize();

        return (
            <AppContentContainer>
                <Stack direction={isDesktop ? 'row' : 'column'} spacing={4}>
                    <Stack
                        alignItems={isDesktop ? 'center' : undefined}
                        flexBasis={isDesktop ? 320 : undefined}
                        flexShrink={0}
                        spacing={isDesktop ? 10 : 2}
                    >
                        <RankedPlayProfile />
                        <Tabs
                            scrollButtons="auto"
                            variant="scrollable"
                            value={tab}
                            orientation={isDesktop ? 'vertical' : 'horizontal'}
                            sx={
                                isDesktop
                                    ? {
                                          '& .MuiTabs-indicator': {
                                              left: 0,
                                              right: 'unset',
                                          },
                                      }
                                    : undefined
                            }
                        >
                            {Object.entries(RankedPlayRoutes).map(([label, route]) => (
                                <Tab
                                    key={label}
                                    label={label}
                                    component={RouterLink}
                                    to={`../${route}`}
                                    value={route}
                                />
                            ))}
                        </Tabs>
                    </Stack>
                    <Box flexGrow={1}>
                        {tab === RankedPlayRoutes['Ranked Games'] && <RankedGames />}
                        {tab === RankedPlayRoutes['All Ranks'] && <AllRanks />}
                        {tab === RankedPlayRoutes.Leaderboard && <GlobalLeaderboard />}
                    </Box>
                </Stack>
            </AppContentContainer>
        );
    }),
);

export const RankedPlayPage = () => {
    return (
        <Routes>
            <Route path={asParentPath(':tab')} element={<RankedPlayPageMain />} />
            <Route path="*" element={<Navigate to={RankedPlayRoutes['Ranked Games']} />} />
        </Routes>
    );
};
