import { inject, injectable } from 'ioc';
import {
    BalanceUpdateMessage,
    BetPostModel,
    GameMissionModel,
    IMatchOddModel,
    MatchModel,
    MatchOddModel,
    TeamModel,
} from '../../common/api/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { calculateTotalCoefficient, toAmerican } from '../utils/odds-calculation';
import { apiClient } from '../../common/api/api-client';
import { LoadStatus } from '../../common/enums/load-status';
import { NotificationsStore } from '../../common/stores/notifications-store';
import { BettingHubStore } from '../../root/stores/betting-hub-store';
import { LiveOddsStore } from '../../common/stores/live-odds-store';

interface ICalculatedOdds {
    totalCoefficient: number;
    totalAmericanOdd: number;
}

export interface BetSlipMatchInfo {
    id: MatchModel['id'];
    teams: TeamModel[];
}

export interface IBetSlipOdd {
    odd: IMatchOddModel;
    matchInfo: BetSlipMatchInfo;
}

@injectable()
export class BetSlipStore {
    @inject(LiveOddsStore) private readonly liveOddsStore!: LiveOddsStore;

    @inject(NotificationsStore)
    private readonly notificationsStore!: NotificationsStore;

    @inject(BettingHubStore) private readonly bettingHubStore!: BettingHubStore;

    placeBetLoadStatus = LoadStatus.None;
    activeOdds: Map<MatchOddModel['id'], IBetSlipOdd> = new Map();
    conflicts: Set<MatchOddModel['id']> = new Set();
    balance = 0;
    minBetAmount = 0;
    totalStake = 0;
    gameId!: number;
    missions: GameMissionModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    initBetSlip = async (gameId: number) => {
        runInAction(() => {
            this.gameId = gameId;
        });

        this.bettingHubStore.connection.on('UpdateBalanceAsync', (data: BalanceUpdateMessage) => {
            if (data.gameId === this.gameId) {
                runInAction(() => {
                    this.balance = data.balance;
                });
            }
        });

        try {
            const [{ balance }, missions] = await Promise.all([
                apiClient.balance(this.gameId, undefined),
                apiClient.getGameMissions(this.gameId),
            ]);

            runInAction(() => {
                this.balance = balance;
                this.missions = missions;
            });
        } catch {
            //
        }
    };

    setTotalStake = (value: number) => {
        this.totalStake = value;
    };

    clearBetSlip = () => {
        this.activeOdds = new Map();
        this.conflicts = new Set();
        this.totalStake = 0;
    };

    setMinBetAmount = (amount: number) => {
        this.minBetAmount = amount;
    };

    selectOdd = async (betSlipOdd: IBetSlipOdd) => {
        const isAdding = !this.activeOdds.has(betSlipOdd.odd.id);

        runInAction(() => {
            if (isAdding) {
                this.activeOdds.set(betSlipOdd.odd.id, betSlipOdd);
            } else {
                this.activeOdds.delete(betSlipOdd.odd.id);
                if (!this.activeOdds.size) {
                    return this.clearBetSlip();
                }
            }
        });

        try {
            const conflicts = await apiClient.getBetConflicts(Array.from(this.activeOdds.keys()));

            runInAction(() => {
                this.conflicts = new Set(conflicts);
            });
        } catch {
            // skip
        }
    };

    placeBet = async () => {
        runInAction(() => {
            this.placeBetLoadStatus = LoadStatus.Loading;
        });

        try {
            const content = new BetPostModel({
                amount: this.totalStake,
                matchOddIds: Array.from(this.activeOdds.values())
                    .map(({ odd }) => odd)
                    .map(({ id }) => id),
                tournamentId: this.gameId,
            });

            await apiClient.placeBet(content);

            this.clearBetSlip();

            runInAction(() => {
                this.placeBetLoadStatus = LoadStatus.Ok;
            });

            this.notificationsStore.notify({
                severity: 'success',
                children: 'Bet was placed successfully!',
            });
        } catch {
            runInAction(() => {
                this.placeBetLoadStatus = LoadStatus.Error;
            });
        }
    };

    get calculatedOdds(): ICalculatedOdds | undefined {
        if (!this.activeOdds.size) {
            return undefined;
        }

        const totalCoefficient = calculateTotalCoefficient(
            Array.from(this.activeOdds.keys()).map(
                id => this.liveOddsStore.liveOdds.get(id)! as MatchOddModel,
            ),
        );
        const totalAmericanOdd = toAmerican(totalCoefficient);

        return {
            totalCoefficient,
            totalAmericanOdd,
        };
    }

    get isPlaceBetButtonDisabled() {
        return (
            !this.totalStake ||
            this.totalStake > this.balance ||
            this.placeBetLoadStatus === LoadStatus.Loading ||
            !!this.conflicts.size ||
            [...this.activeOdds].some(([id]) => this.liveOddsStore.disabledOdds.has(id))
        );
    }

    get amountToWin() {
        return this.calculatedOdds
            ? Math.floor(this.calculatedOdds.totalCoefficient * Number(this.totalStake))
            : 0;
    }
}
