import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { RankedGamesStore } from '../../stores/ranked-games-store';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import { TableColumnConfig } from '../../../common/utils/types';
import { RankedGameWithParticipantModel } from '../../../common/api/api';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { formatDateWithName } from '../../../../utils';
import { Balance } from 'shared';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { Stack, useTheme } from '@mui/material';
import { AppStore } from '../../../common/stores/app-store';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DoorOpen, HandWaving, SquareHalf } from '@phosphor-icons/react';
import {
    customNegative,
    customNeutral,
    customPrimary,
} from '../../../redesigned-modules/root/components/theme-provider/colors';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '../../../common/components/action-button/action-button';
import { RankedGamesMobile } from './mobile/ranked-games-mobile';
import { SportSelectionDialog } from '../../../sport-selection/components/sport-selection-dialog';

const rankedGamesColumns: TableColumnConfig<RankedGameWithParticipantModel>[] = [
    {
        title: 'Game ID',
        getComponent: game => <TypographyTableCell>#{game.id}</TypographyTableCell>,
    },
    {
        title: 'Start Date',
        getComponent: game => (
            <TypographyTableCell>
                {formatDateWithName(game.timeRange.startDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: game => (
            <TypographyTableCell>{formatDateWithName(game.timeRange.endDate)}</TypographyTableCell>
        ),
    },
    {
        title: 'Current Balance',
        getComponent: game => (
            <Balance
                typographyProps={{ fontWeight: 700 }}
                amount={game.participant?.balance ?? game.initialBalance}
            />
        ),
    },
    {
        title: 'Leagues',
        getComponent: game => <SportLeagueIconList leagues={game.leagues} />,
    },
];

export const RankedGames = provide([RankedGamesStore])(
    observer(() => {
        const [
            {
                fetchGames,
                loadStatus,
                games,
                gameToShowSportSelection,
                register,
                leave,
                showSportSelection,
                hideSportSelection,
            },
        ] = useDependencies(RankedGamesStore);
        const theme = useTheme();
        const { isMobile, isDesktop } = useScreenSize();

        useEffect(() => {
            fetchGames();
        }, []);

        const navigate = useNavigate();

        const handleShowSportSelection = (game: RankedGameWithParticipantModel) => {
            showSportSelection(game);
        };

        const handleRegister = (game: RankedGameWithParticipantModel) => {
            register(game);
        };

        const handleLeave = (game: RankedGameWithParticipantModel) => {
            leave(game);
        };

        const handleEnter = (game: RankedGameWithParticipantModel) => {
            navigate(`/my-games/content/${game.participant?.gameId ?? game.gameId}`);
        };

        let asyncList = (
            <AsyncList length={games.length} loadStatus={loadStatus} onFetch={fetchGames}>
                {setLastElement =>
                    isMobile ? (
                        <RankedGamesMobile
                            onLeave={handleLeave}
                            onEnter={handleEnter}
                            setLastElement={setLastElement}
                            onRegister={handleRegister}
                            onShowSportSelection={handleShowSportSelection}
                        />
                    ) : (
                        <CustomTable>
                            <TableBody>
                                {games.map((game, gameIndex) => (
                                    <TableRow
                                        key={game.id}
                                        ref={
                                            gameIndex === games.length - 1
                                                ? setLastElement
                                                : undefined
                                        }
                                    >
                                        {rankedGamesColumns.map((column, columnIndex) => (
                                            <TableCell key={column.title}>
                                                {column.getComponent(game)}
                                            </TableCell>
                                        ))}
                                        <TableCell align="right" className="table_actions">
                                            <Stack direction="row" spacing={1} justifyContent="end">
                                                <ActionButton
                                                    onClick={() => handleShowSportSelection(game)}
                                                    icon={<SquareHalf size={24} />}
                                                    variant="contained"
                                                    sx={{
                                                        background: customPrimary[350],
                                                        color: customNeutral[950],
                                                    }}
                                                />
                                                {game.participant ? (
                                                    <Fragment>
                                                        <ActionButton
                                                            onClick={() => handleEnter(game)}
                                                            endIcon={<DoorOpen weight="fill" />}
                                                            color="primary"
                                                            variant="contained"
                                                        >
                                                            Enter
                                                        </ActionButton>
                                                        {game.participant.canLeave && (
                                                            <ActionButton
                                                                onClick={() => handleLeave(game)}
                                                                sx={{
                                                                    background: customNegative[600],
                                                                }}
                                                                endIcon={<DoorOpen weight="fill" />}
                                                                variant="contained"
                                                            >
                                                                Leave
                                                            </ActionButton>
                                                        )}
                                                    </Fragment>
                                                ) : (
                                                    <ActionButton
                                                        onClick={() => handleRegister(game)}
                                                        endIcon={<HandWaving />}
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        Register
                                                    </ActionButton>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </CustomTable>
                    )
                }
            </AsyncList>
        );

        if (isDesktop) {
            asyncList = (
                <Stack spacing={2}>
                    <Typography fontSize={32} fontWeight={600}>
                        Ranked Games
                    </Typography>
                    <Box
                        sx={{
                            maxHeight: '75dvh',
                            overflowY: 'auto',
                            borderRadius: '20px',
                        }}
                    >
                        {asyncList}
                    </Box>
                </Stack>
            );
        }

        return (
            <Fragment>
                {asyncList}
                {gameToShowSportSelection && (
                    <SportSelectionDialog
                        onClose={hideSportSelection}
                        gameId={gameToShowSportSelection.gameId}
                        onStartBetting={() => handleEnter(gameToShowSportSelection)}
                    />
                )}
            </Fragment>
        );
    }),
);
