import { injectable } from 'ioc';
import { makeAutoObservable, runInAction } from 'mobx';

import { FieldState, FormState } from 'formstate';
import { formValidatorsWithCustomMessages } from '../../common/utils/form-state-validators';
import { LoadStatus } from '../../common/enums/load-status';
import { apiClient } from '../../common/api/api-client';
import { PasswordTokenizedResetModel } from '../../common/api/api';

@injectable()
export class ResetPasswordStore {
    isPasswordReset = false;
    resetPasswordLoadStatus = LoadStatus.None;

    formState = new FormState({
        newPassword: new FieldState('').validators(
            formValidatorsWithCustomMessages.password('Password must meet the required criteria.'),
        ),
        confirmPassword: new FieldState('').validators(
            formValidatorsWithCustomMessages.password('Passwords do not match.'),
            value => {
                if (value !== this.formState.$.newPassword.value) {
                    return 'Passwords do not match';
                }
            },
        ),
    });

    constructor() {
        makeAutoObservable(this);
        this.formState.disableAutoValidation();
    }

    resetPassword = async (token: string) => {
        try {
            runInAction(() => {
                this.resetPasswordLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: any = {
                token,
                newPassword: this.formState.$.newPassword.$,
            };

            // need to add api client
            const response = await apiClient.tokenPATCH(new PasswordTokenizedResetModel(payload));
            const { accessToken } = response;

            localStorage.setItem('token', accessToken);

            runInAction(() => {
                this.resetPasswordLoadStatus = LoadStatus.Ok;
                this.isPasswordReset = true;
            });

            return true;
        } catch {
            runInAction(() => {
                this.resetPasswordLoadStatus = LoadStatus.Error;
            });
        }
    };
}
