import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { LoginStore } from '../../stores/login.store';

import { StyledFormFrame } from '../brand-components/brand-form-frame';

import { LoginForm } from './login-form';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getRedirectUriForDiscord } from '../../utils/third-party-utils';

export const Login = provide([LoginStore])(
    observer(() => {
        const [{ loginWithDiscordCode }] = useDependencies(LoginStore);
        const [searchParams] = useSearchParams();

        useEffect(() => {
            const code = searchParams.get('code');

            if (code) {
                loginWithDiscordCode(code!, getRedirectUriForDiscord());
            }
        }, [loginWithDiscordCode, searchParams]);

        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
                <StyledFormFrame>
                    <LoginForm />
                </StyledFormFrame>
            </GoogleOAuthProvider>
        );
    }),
);
