import { Fragment, useState, MouseEvent, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useDependencies, provide } from 'ioc';
import { UserNotificationsStore } from '../stores/user-notifications-store';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { Popover } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import NotificationSound from '../../../assets/sounds/notification-sound.wav';
import { UserNotificationsContent } from './user-notifications-content';
import { noop } from '../../common/utils/noop';
import { Bell as BellIcon } from '@phosphor-icons/react';
import { useScreenSize } from '../../common/hooks/use-is-mobile';

export const UserNotifications = provide([UserNotificationsStore])(
    observer(() => {
        const [{ init, badgeCount, fetchAll }] = useDependencies(UserNotificationsStore);
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const audioPlayer = useRef<HTMLAudioElement>(null);
        const prevBadgeCount = useRef(badgeCount);
        const screen = useScreenSize();
        const isOpen = !!anchorEl;

        useEffect(() => {
            init();
        }, [init]);

        useEffect(() => {
            if (badgeCount > prevBadgeCount.current) {
                audioPlayer.current?.play().catch(noop);
            }

            prevBadgeCount.current = badgeCount;
        }, [badgeCount, audioPlayer.current]);

        const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
            fetchAll();
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        console.log(screen);

        const { isMobile, isTablet } = screen;

        return (
            <Fragment>
                <IconButton onClick={handleClick}>
                    <Badge
                        badgeContent={badgeCount}
                        sx={{
                            '& .MuiBadge-badge': {
                                background:
                                    'linear-gradient(146deg, #5BE734 -23.07%, #2B5FD9 86.24%);',
                                boxShadow: '0px 0px 6px 0px rgba(255, 255, 255, 0.40)',
                            },
                        }}
                    >
                        <BellIcon size={32} weight="bold" color={isOpen ? '#58F081' : undefined} />
                    </Badge>
                </IconButton>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    sx={{
                        '& .MuiPaper-root': {
                            background: 'transparent',
                            borderRadius: '8px',
                            boxShadow: '0px 0px 6px 0px rgba(255, 255, 255, 0.40)',
                            width: isMobile ? '95vw' : undefined,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: isMobile ? 'center' : 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: isMobile ? 'center' : 'right',
                    }}
                >
                    <UserNotificationsContent />
                </Popover>
                <audio ref={audioPlayer} src={NotificationSound} />
            </Fragment>
        );
    }),
);
