import { observer } from 'mobx-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useReturnURL } from '../../../../../hooks/use-return-url';

import { AuthLayout } from '../../../../auth/components/layouts/auth-layout';
import { Login } from '../../../../auth/components/login/login';
import { Signup } from '../../../../auth/components/signup/signup';
import { ForgotPassword } from '../../../../auth/components/forgot-password/forgot-password';
import { ResetPassword } from '../../../../auth/components/reset-password/reset-password';
import { ContactUs } from '../../../../auth/components/contact-us/contact-us';
import { DiscordCallback } from '../../../../auth/components/discord-callback/discord-callback';

export const UnauthenticatedRoutes = {
    login: 'login',
    signup: 'signup',
    forgotPassword: 'forgot-password',
    passwordReset: 'password-reset',
    contacts: 'contacts',
} as const;

export const ExternalRoutes = {
    discordCallback: 'discord-callback',
    googleCallback: 'google-callback',
} as const;

export const AppUnauthenticated = observer(() => {
    const redirectionURL = useReturnURL();

    return (
        <Routes>
            <Route element={<AuthLayout />}>
                <Route path={UnauthenticatedRoutes.login} element={<Login />} />

                <Route path={UnauthenticatedRoutes.signup} element={<Signup />} />
                <Route path={UnauthenticatedRoutes.forgotPassword} element={<ForgotPassword />} />
                <Route path={UnauthenticatedRoutes.passwordReset} element={<ResetPassword />} />
                <Route path={UnauthenticatedRoutes.contacts} element={<ContactUs />} />

                <Route path={ExternalRoutes.discordCallback} element={<DiscordCallback />} />
                <Route path={ExternalRoutes.googleCallback} element={<Login />} />

                <Route
                    path="*"
                    element={
                        <Navigate
                            to={{
                                pathname: 'login',
                                search: redirectionURL,
                            }}
                            replace
                        />
                    }
                />
            </Route>
        </Routes>
    );
});
